import React, { useState, useEffect } from "react";
import axios from "axios"
import PlainHeader from "../../components/Headers/PlainHeader.js";
import { Container, Row, Card, CardHeader, CardBody, Form, Col, Input, FormGroup, Label, Button, Table, Spinner} from "reactstrap";
import useAcquireTokenSilent from "../../common/useAcquireTokenSilent";

const AddOrder = (props) => {
    const { getToken } = useAcquireTokenSilent();
    const [loading, setLoading] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [customerFilter, setCustomerFilter] = useState('');

    const matchedCustomers = customers.filter(f => f.name.toUpperCase().includes(customerFilter.toUpperCase()));;

    useEffect(() => {
        getToken(props.configuration)
            .then((token) => {
                axios.get(
                    '/api/WaveCustomers/',
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    }
                ).then((result) => {
                    setCustomers(result.data.sort((a, b) => a.name.localeCompare(b.name)));
                    setLoading(false);
                });
            });
    }, [getToken, props.configuration]);

    const createOrder = (customerId, customerName) => {
        getToken(props.configuration)
            .then((token) => {
                axios(
                    {
                        method: "POST",
                        url: '/api/Orders/',
                        data: { WaveCustomerId: customerId, CustomerName: customerName },
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': 'Bearer ' + token
                        }
                    }
                ).then((result) => {
                    props.history.push('/OrderDetails/' + result.data.orderId);
                });
            });
    }

    const resultsTable = <Row>
        <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
                <tr>
                    <th scope="col">Customer Name</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {loading ? <tr><td colspan="2"><Spinner color="primary" /></td></tr> : matchedCustomers.slice(0, 10).map(c =>
                    <tr key={c.id}>
                        <td>{c.name}</td>
                        <th><Button color="primary" onClick={() => createOrder(c.id, c.name)}>Create Manual Order</Button></th>
                    </tr>)}
                {matchedCustomers.length > 10 ? <tr><td colspan="2">More than 10 results returned.</td></tr> : null}
                {matchedCustomers.length === 0 && !loading ? <tr><td colspan="2">No matching customers.</td></tr> : null}
            </tbody>
        </Table>
    </Row>;

    return (<>
        <PlainHeader />
        <Container className="mt--7" fluid>
            <Row>
                <div className="col">
                    <Card className="shadow" body>
                        <CardHeader>
                            <h3 className="mb-0">Create Order</h3>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <div className="col">
                                    <Form>
                                        <Row form>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="customer">Customer</Label>
                                                    <Input type="customer" name="customer" id="customer" placeholder="Customer Name" value={customerFilter} onChange={(e) => setCustomerFilter(e.target.value)} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                            {resultsTable}
                        </CardBody>
                    </Card>
                </div>
            </Row>
        </Container>
    </>);
};

export default AddOrder;