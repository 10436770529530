import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios"
import PlainHeader from "../../components/Headers/PlainHeader.js";
import { Container, Row, Card, CardHeader, CardBody, Nav, Form, FormGroup, Label, Input, Button } from "reactstrap";
import useAcquireTokenSilent from "../../common/useAcquireTokenSilent";

const AddProduct = (props) => {
    const { sku } = useParams();
    const { getToken } = useAcquireTokenSilent();

    const [data, setData] = useState({
        waveSid: '',
        categoryIdToReset: ''
    })
    const [categories, setCategories] = useState([]);

    const getSubCategories = useCallback((parent, allCategories, dashes) => {
        const newSubCategories = [];

        allCategories.forEach(sc => {
            if (sc.parentCategoryId === parent.id) {
                newSubCategories.push({ ...sc, selectName: dashes + sc.name });
                getSubCategories(sc, allCategories, dashes + '- ').forEach(nc => newSubCategories.push(nc));
            }
        });
        return newSubCategories;
    }, []);


    useEffect(() => {
        getToken(props.configuration)
            .then((token) => {
                axios.get(
                    '/api/Categories/',
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    }
                ).then((result) => {
                    var categoryTree = [];

                    result.data.sort((a, b) => a.shortName.localeCompare(b.shortName));

                    result.data.forEach(c => {
                        if (c.parentCategoryId === null) {
                            categoryTree.push({ ...c, selectName: c.name });
                            getSubCategories(c, result.data, ' - ').forEach(nc => categoryTree.push(nc));
                        }
                    });
                    setCategories(categoryTree);
                });
            });
    }, [sku, getToken, props.configuration, props.history, getSubCategories]);

    const onchange = e => {
        const field = e.target.id;
        const value = e.target.value;
        setData(prev => {
            const newState = { ...prev };
            if (field === "price") {
                newState[field] = Number(value);
            } else {
                newState[field] = value;
            }
            return newState;
        });
    }

    const processPayouts = () => {
        getToken(props.configuration)
            .then((token) => {
                axios({
                    method: "POST",
                    url: "/api/WaveCustomers/ProcessPayouts/" + data.waveSid,
                    data: { },
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(function (response) {
                        alert('Payouts processed succesfully!');
                    })
                    .catch(function (response) {
                        alert('Error processing payouts!');
                    });
            });
    }

    const resetViewStatistics = () => {
        getToken(props.configuration)
            .then((token) => {
                axios({
                    method: "POST",
                    url: "/api/Categories/" + data.categoryIdToReset +"/ResetStatistics/" ,
                    data: {},
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(function (response) {
                        alert('Category statistics reset succesfully!');
                    })
                    .catch(function (response) {
                        alert('Error resetting category statistics!');
                    });
            });
    }
    return (<>
        <PlainHeader />
        <Container className="mt--7" fluid>
            <Row>
                <div className="col">

                    <Card className="shadow">
                        <CardHeader className="bg-transparent">
                            <Row className="align-contents-centre">
                                <div className="col">
                                    <h3 className="mb-0">Process Payouts</h3>
                                </div>
                                <div className="col">
                                    <Nav className="justify-content-end" pills>
                                        <Button color="primary" size="md" onClick={() => processPayouts()}>Process Payouts</Button>
                                    </Nav>
                                </div>
                            </Row>
                        </CardHeader>
                        <CardBody>

                            <Form>
                                <FormGroup>
                                    <Label for="name">Wave SID</Label>
                                    <Input type="text" name="waveSid" id="waveSid" placeholder="" value={data.waveSid} onChange={(e) => onchange(e)} valid={data.waveSid.length > 0} invalid={data.waveSid.length === 0} />
                                </FormGroup>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
                <div className="col">

                    <Card className="shadow">
                        <CardHeader className="bg-transparent">
                            <Row className="align-contents-centre">
                                <div className="col">
                                    <h3 className="mb-0">Reset View Statistics</h3>
                                </div>
                                <div className="col">
                                    <Nav className="justify-content-end" pills>
                                        <Button color="primary" size="md" onClick={() => resetViewStatistics()}>Reset View Statistics</Button>
                                    </Nav>
                                </div>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <FormGroup>
                                    <Label for="productType">Category</Label>
                                    <Input type="select" name="categoryIdToReset" id="categoryIdToReset" value={data.categoryIdToReset} onChange={(e) => onchange(e)} >
                                        <option>Select One</option>
                                        {categories.map(c => <option key={c.id} value={c.id}>{c.selectName}</option>)}
                                    </Input>
                                </FormGroup>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </Row>
        </Container>
    </>);
};


export default AddProduct;