import React, { useCallback, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import axios from "axios"
import PlainHeader from "../../components/Headers/PlainHeader.js";
import { Container, Row, Card, CardHeader, CardBody, List, Spinner } from "reactstrap";
import useAcquireTokenSilent from "../../common/useAcquireTokenSilent";

const CategoryList = (props) => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const { getToken } = useAcquireTokenSilent();

    const getSubCategories = useCallback((parent, allCategories) => {
        var newSubCategories = [];

        allCategories.forEach(sc => {
            if (sc.parentCategoryId === parent.id) {
                newSubCategories.push({ ...sc, subCategories: getSubCategories(sc, allCategories) });
            }
        });
        newSubCategories.sort((a, b) => a.shortName.localeCompare(b.shortName));
        return newSubCategories;
    },[]);

    useEffect(() => {
        getToken(props.configuration)
            .then((token) => {
                axios.get(
                    '/api/Categories/',
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    }
                ).then((result) => {
                    var categoryTree = [];

                    result.data.forEach(c => {
                        if (c.parentCategoryId === null || c.parentCategoryId === "") {
                            categoryTree.push({ ...c, subCategories: getSubCategories(c, result.data) });
                        }
                    });

                    categoryTree.sort((a, b) => a.shortName.localeCompare(b.shortName));
                    setCategories(categoryTree);
                    setLoading(false);
                });
            });
    }, [getSubCategories, getToken, props.configuration]);

    const subCategoryNode = function (category) {
        return (
            <li key={category.id}>{category.shortName}
                <ul key={category.id}>
                    <li key={category.id + "-new"}><Link to={"/categories/" + category.id + "/add"}>Add New Subcategory</Link></li>
                    {category.subCategories.map(c => c.subCategories.length === 0
                        ? <li key={c.id}>{c.shortName} | <Link to={"/categories/" + c.id + "/add"}>Add New Subcategory</Link></li>
                        : subCategoryNode(c))}
                </ul>
            </li>);
    }

    const categoryList = (
        <List>
            <li key={"new"}><Link to="/categories/add">Add Category</Link></li>
            {categories.map(c => c.subCategories.length === 0
                ? <li key={c.id}>{c.name} | <Link to={"/categories/" + c.id + "/add"}>Add New Subcategory</Link></li>
                : subCategoryNode(c))}
        </List>);
    return (<>
        <PlainHeader />
        <Container className="mt--7" fluid>
            <Row>
                <div className="col">
                    <Card className="shadow">
                        <CardHeader className="bg-transparent">
                            <h3 className="mb-0">Categories</h3>
                        </CardHeader>
                        <CardBody>
                            {loading ? <Spinner color="primary" /> : categoryList}
                        </CardBody>
                    </Card>
                </div>
            </Row>
        </Container>
    </>);
};


export default CategoryList;