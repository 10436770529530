import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios"
import PlainHeader from "../../components/Headers/PlainHeader.js";
import { Container, Row, Col, Card, CardHeader, CardBody, CardImg, Nav, Form, FormGroup, Label, Input, Button, Table, InputGroup } from "reactstrap";
import useAcquireTokenSilent from "../../common/useAcquireTokenSilent";
import OtherCategoryEditor from "../../components/Products/OtherCategoryEditor";

const AddProduct = (props) => {
    const { sku } = useParams();
    const { getToken } = useAcquireTokenSilent();
    const [step, setStep] = useState(1);
    const [availableImages, setAvailableImages] = useState([]);
    const [primaryImage, setPrimaryImage] = useState(null);
    const [facebookImage, setFacebookImage] = useState(null);
    const [saving, setSaving] = useState(false);
    const [alternateImages, setAlternateImages] = useState([]);
    const [productDetails, setProductDetails] = useState({
        name: '',
        slug: '',
        insideText: '',
        outsideText: '',
        language: 'maltese',
        primaryCategory: '',
        price: 3.5,
        productType: '1',
        personalisableSettings: [],
        otherCategories: [],
        tags: []
    });
    const [tagToAdd, setTagToAdd] = useState('');

    const validation = {
        name: productDetails.name.length > 0,
        insideText: productDetails.insideText.length > 0,
        outsideText: productDetails.outsideText.length > 0,
        language: productDetails.language.length > 0,
        primaryCategory: productDetails.primaryCategory.length > 0,
        price: productDetails.price > 0,
        productType: productDetails.productType === '1' || productDetails.productType === '2' || productDetails.productType === '3',
        personalisableSettings: productDetails.productType === '1' || productDetails.productType === '3' || (productDetails.productType === '2' && productDetails.personalisableSettings.length > 0),
        isValid: function () {
            return this.name && this.insideText && this.outsideText
                && this.language && this.primaryCategory && this.price
                && this.productType && this.personalisableSettings;
        }
    };
    const [newPersonalisableSetting, setNewPersonalisableSetting] = useState({
        code: '',
        name: '',
        maximumLength: 30
    });
    const newPersonalisableSettingValidation = {
        code: newPersonalisableSetting.code.length > 0 && productDetails.personalisableSettings.filter(x => x.code === newPersonalisableSetting.code).length === 0,
        name: newPersonalisableSetting.name.length > 0,
        maximumLength: newPersonalisableSetting.maximumLength > 0,
        isValid: function () {
            return this.code && this.name && this.maximumLength;
        }
    }
    const [categories, setCategories] = useState([]);

    const getSubCategories = useCallback((parent, allCategories, dashes) => {
        var newSubCategories = [];

        allCategories.forEach(sc => {
            if (sc.parentCategoryId === parent.id) {
                newSubCategories.push({ ...sc, selectName: dashes + sc.name });
                getSubCategories(sc, allCategories, dashes + '- ').forEach(nc => newSubCategories.push(nc));
            }
        });
        return newSubCategories;
    }, []);

    const addOtherCategory = (categoryToAdd) => {
        setProductDetails(prev => {
            var newState = { ...prev };
            newState.otherCategories.push(categoryToAdd);
            return newState;
        });
    }

    const removeOtherCategory = (id) => {
        setProductDetails(prev => {
            var newState = { ...prev };
            newState.otherCategories = newState.otherCategories.filter(x => x !== id);
            return newState;
        });
    }
    const addTag = () => {
        setProductDetails(prev => {
            var newState = { ...prev };
            newState.tags.push({ slug: slugify(tagToAdd), name: tagToAdd });
            return newState;
        });
        setTagToAdd('');
    }
    const removeTag = (slug) => {
        setProductDetails(prev => {
            var newState = { ...prev };
            newState.tags = newState.tags.filter(x => x.slug !== slug);
            return newState;
        });
    }

    useEffect(() => {
        getToken(props.configuration)
            .then((token) => {
                axios.get(
                    '/api/Products/' + sku,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    }
                ).then((result) => {
                    debugger;
                    var existingProduct = result.data;

                    if (existingProduct.productStatus !== 0) {
                        alert('Product already exists!');
                        props.history.push("/Products/Add");
                    } else {
                        setProductDetails(prev => {
                            var newState = { ...prev };
                            newState.insideText = existingProduct.insideText;
                            newState.outsideText = existingProduct.outsideText;
                            newState.tags = JSON.parse(JSON.stringify(existingProduct.tags));
                            return newState;
                        });
                    }
                });

                axios.get(
                    '/api/Mockups/' + sku + '/Images',
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    }
                ).then((result) => {
                    setAvailableImages(result.data);
                });
                axios.get(
                    '/api/Categories/',
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    }
                ).then((result) => {
                    var categoryTree = [];

                    result.data.forEach(sc => {
                        if (sku.toUpperCase().includes('-' + sc.skuCode + '-')) {
                            setProductDetails(prev => {
                                var result = { ...prev };
                                result.primaryCategory = sc.id;
                                return result;
                            });
                        }
                    });

                    result.data.sort((a, b) => a.shortName.localeCompare(b.shortName));

                    result.data.forEach(c => {
                        if (c.parentCategoryId === null) {
                            categoryTree.push({ ...c, selectName: c.name });
                            getSubCategories(c, result.data, ' - ').forEach(nc => categoryTree.push(nc));
                        }
                    });
                    setCategories(categoryTree);
                });
            });
    }, [sku, getToken, props.configuration, props.history, getSubCategories]);

    const addProductClicked = () => {
        
        if (validation.isValid()) {
            setSaving(true);
            getToken(props.configuration)
                .then((token) => {
                    axios({
                        method: "POST",
                        url: "/api/products/"+sku+ "/Process",
                        data: { ...productDetails, sku, primaryImage, facebookImage, alternateImages },
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': 'Bearer ' + token
                        }
                    })
                        .then(function (response) {
                            props.history.push('/Products/Add');
                        })
                        .catch(function (response) {
                            alert('Error creating product!');
                            setSaving(false);
                        });
                });
        }
    }

    const onchange = e => {
        const field = e.target.id;
        const value = e.target.value;
        setProductDetails(prev => {
            var newState = { ...prev };
            if (field === "price") {
                newState[field] = Number(value);
            } else {
                newState[field] = value;
            }
            if (field === "name") {
                newState.slug = slugify(value);
            }
            if (field === "productType") {
                switch (value) {
                    case "1":
                        newState.price = 3.50;
                        break;
                    case "2":
                        newState.price = 5.00;
                        break;
                    case "3":
                        newState.price = 5.50;
                        break;
                    default:
                        break;
                }
            }
            return newState;
        });
    }

    const [imageKey, setImageKey] = useState(0);
    const uploadImage = async (file: File) => {
        getToken(props.configuration)
            .then((token) => {
                const formData = new FormData();
                formData.append('Image', file);

                axios.post(
                    '/api/Products/' + sku + '/SingleImageTemplate',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'Bearer ' + token
                        }
                    }
                ).then(r => {
                    debugger;
                    const newImageKey = imageKey + 1;
                    setImageKey(newImageKey);
                }).catch(r => {
                });
            });
    };
    const addPersonalisedSetting = e => {
        setProductDetails(prev => {
            var newState = { ...prev };
            newState.personalisableSettings.push(newPersonalisableSetting);
            return newState;
        });
        setNewPersonalisableSetting(
            {
                code: '',
                name: '',
                maximumLength: 30
            });
    }

    const removePersonalisedSetting = code => {
        setProductDetails(prev => {
            var newState = { ...prev };
            for (var i = 0; i < newState.personalisableSettings.length; i++) {
                if (newState.personalisableSettings[i].code === code) {
                    newState.personalisableSettings.splice(i, 1);
                }
            }
            return newState;
        });
    }

    const onNewPersonalisableSettingChange = e => {
        const field = e.target.id;
        const value = e.target.value;
        setNewPersonalisableSetting(prev => {
            var newState = { ...prev };
            if (field === "maximumLength") {
                newState[field] = Number(value);
            } else {
                newState[field] = value;
            }
            return newState;
        });
    }

    const clickPrimaryImage = (imageUrl) => {
        setPrimaryImage(imageUrl);
        setStep(2);

        const splitPrimaryImage = imageUrl.split('_');
        if (splitPrimaryImage.length >= 3 && !splitPrimaryImage[splitPrimaryImage.length - 1].toUpperCase().includes('NB.JPG')) {
            const backgroundCode = splitPrimaryImage[splitPrimaryImage.length - 2];
            setAlternateImages(availableImages
                .filter(f => f !== imageUrl)
                .filter(f =>
                    f.toUpperCase().endsWith(backgroundCode + "_FB01.JPG")
                    || f.toUpperCase().endsWith(backgroundCode + "_FB02.JPG")
                    || f.toUpperCase().endsWith(backgroundCode + "_FB03.JPG")
                    || f.toUpperCase().endsWith("BG02_FB04-NB.JPG")));
        }
    }

    const clickFacebookImage = (imageUrl) => {
        setFacebookImage(imageUrl);
        setStep(3);
    }

    const clickAvailableImage = (imageUrl) => {
        setAlternateImages(prev => prev.concat(imageUrl));
    }

    const clickAlternateImage = (imageUrl) => {
        setAlternateImages(prev => {
            const newArray = [];
            prev.filter(f => f !== imageUrl).forEach(f => newArray.push(f));
            return newArray;
        });
    }

    const primaryImages = availableImages
        .filter(f => f.toUpperCase().endsWith('_FB01.JPG') || f.toUpperCase().endsWith('_FB02.JPG') || f.toUpperCase().endsWith('_FB04-NB.JPG'));

    const facebookImages = [];
    if (step === 2) {
        const splitPrimaryImage = primaryImage.split('_');
        const tempFacebookImages = [];
        if (splitPrimaryImage.length >= 3 && !splitPrimaryImage[splitPrimaryImage.length - 1].toUpperCase().includes('NB.JPG')) {
            const backgroundCode = splitPrimaryImage[splitPrimaryImage.length - 2];
            availableImages.filter(f => f.toUpperCase().includes(backgroundCode + '_FBL0')).forEach(i => tempFacebookImages.push(i));
            availableImages.filter(f => f.toUpperCase().includes('_FBL0') && f.toUpperCase().endsWith('NB.JPG')).forEach(i => tempFacebookImages.push(i));
        } else {
            availableImages.filter(f => f.toUpperCase().includes('_FBL0')).forEach(i => tempFacebookImages.push(i));
        }
        [...new Set(tempFacebookImages)].forEach(i => facebookImages.push(i));

    }

    const availableAlternateImages = [];
    if (step === 3) {
        const splitPrimaryImage = primaryImage.split('_');
        if (splitPrimaryImage.length >= 3 && !splitPrimaryImage[splitPrimaryImage.length - 1].toUpperCase().includes('NB.JPG')) {
            const backgroundCode = splitPrimaryImage[splitPrimaryImage.length - 2];
            availableImages
                .filter(f => f !== primaryImage && !alternateImages.includes(f))
                .filter(f =>
                    f.toUpperCase().endsWith(backgroundCode + "_FB01.JPG")
                    || f.toUpperCase().endsWith(backgroundCode + "_FB02.JPG")
                    || f.toUpperCase().endsWith(backgroundCode + "_FB03.JPG")
                    || f.toUpperCase().endsWith("BG02_FB04-NB.JPG"))
                .forEach(i => availableAlternateImages.push(i));
        } else {
            availableImages
                .filter(f => f !== primaryImage && !alternateImages.includes(f))
                .filter(f =>
                    f.toUpperCase().endsWith("_FB01.JPG")
                    || f.toUpperCase().endsWith("_FB02.JPG")
                    || f.toUpperCase().endsWith("_FB03.JPG")
                    || f.toUpperCase().endsWith("BG02_FB04-NB.JPG"))
                .forEach(i => availableAlternateImages.push(i));
        }
        availableImages
            .filter(f => f !== primaryImage && !alternateImages.includes(f) && f.toUpperCase().includes('_BG02_CM'))
            .forEach(i => availableAlternateImages.push(i));
    }

    const frontDisplayImage = availableImages.filter(f => f.toUpperCase().endsWith('-FRONT.PNG'))[0];
    const insideDisplayImage = availableImages.filter(f => f.toUpperCase().endsWith('-INRIGHT.PNG'))[0];

    const slugify = (string) => {
        const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
        const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
        const p = new RegExp(a.split('').join('|'), 'g')

        return string.toString().toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
            .replace(/&/g, '-and-') // Replace & with 'and'
            .replace(/[^\w-]+/g, '') // Remove all non-word characters
            .replace(/--+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, '') // Trim - from end of text
    }

    const step1 = <>
        <h2>Select Primary Image</h2>
        <Row>
            {primaryImages
                .map(i =>
                    <div className="col" key={i}>
                        <Card style={{ width: "14rem" }}>
                            <CardImg
                                alt="..."
                                src={i}
                                top
                            />
                            <CardBody>
                                <Button color="primary" onClick={() => clickPrimaryImage(i)}>Select As Primary </Button>
                            </CardBody>
                        </Card>
                    </div>)}
        </Row>
    </>

    const step2 =
        <>
            <h2>Select Facebook Image</h2>
            <Row>
                {facebookImages
                    .map(i =>
                        <div className="col" key={i}>
                            <Card style={{ width: "14rem" }}>
                                <CardImg
                                    alt="..."
                                    src={i}
                                    top
                                />
                                <CardBody>
                                    <Button color="primary" onClick={() => clickFacebookImage(i)}>Select As Facebook Image </Button>
                                </CardBody>
                            </Card>
                        </div>)}
            </Row>
        </>

    const step3 =
        <>
            <Row>
                <div className="col-7">
                    <h2>Available Images</h2>
                    <Row>
                        {availableAlternateImages
                            .map(i =>
                                <div className="col" key={i}>
                                    <Card style={{ width: "14rem" }}>
                                        <CardImg
                                            alt="..."
                                            src={i}
                                            top
                                        />
                                        <CardBody>
                                            <Button color="success" onClick={() => clickAvailableImage(i)}>Add Image </Button>
                                        </CardBody>
                                    </Card>
                                </div>)}
                    </Row>
                </div>
                <div className="col-5">
                    <h2>Selected Images ({alternateImages.length})</h2>
                    <Row>
                        {alternateImages
                            .map(i =>
                                <div className="col" key={i}>
                                    <Card style={{ width: "14rem" }}>
                                        <CardImg
                                            alt="..."
                                            src={i}
                                            top
                                        />
                                        <CardBody>
                                            <Button color="danger" onClick={() => clickAlternateImage(i)}>Remove Image </Button>
                                        </CardBody>
                                    </Card>
                                </div>)}
                    </Row>
                </div>
            </Row>
        </>

    const personalisableProductSettings =
        <Table className="align-items-center table-flush" responsive size="sm">
            <thead className="thead-light">
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Code</th>
                    <th scope="col">Name</th>
                    <th scope="col">Maximum Length</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {productDetails.personalisableSettings.map(i =>
                    <tr key={i.code}>
                        <td>
                            <i className="ni ni-ruler-pencil" />
                        </td>
                        <td>
                            {i.code}
                        </td>
                        <td>
                            {i.name}
                        </td>
                        <td>
                            {i.maximumLength}
                        </td>
                        <td>
                            <Button color="primary" disabled={saving} onClick={() => removePersonalisedSetting(i.code)}>Remove</Button>
                        </td>
                    </tr>
                )}
                <tr>
                    <td>
                        <i className="ni ni-fat-add" />
                    </td>
                    <td>
                        <Input type="text" name="code" id="code" placeholder="Code" value={newPersonalisableSetting.code} onChange={(e) => onNewPersonalisableSettingChange(e)} valid={newPersonalisableSettingValidation.code} invalid={!newPersonalisableSettingValidation.code} />
                    </td>
                    <td>
                        <Input type="text" name="name" id="name" placeholder="Name" value={newPersonalisableSetting.name} onChange={(e) => onNewPersonalisableSettingChange(e)} valid={newPersonalisableSettingValidation.name} invalid={!newPersonalisableSettingValidation.name} />
                    </td>
                    <td>
                        <Input type="number" name="maximumLength" id="maximumLength" placeholder="30" value={newPersonalisableSetting.maximumLength} onChange={(e) => onNewPersonalisableSettingChange(e)} valid={newPersonalisableSettingValidation.maximumLength} invalid={!newPersonalisableSettingValidation.maximumLength} />
                    </td>
                    <td>
                        <Button color="primary" disabled={!newPersonalisableSettingValidation.isValid() || saving} onClick={() => addPersonalisedSetting()}>Add</Button>
                    </td>
                </tr>
            </tbody>
        </Table>;

    const singleImageProductInputs =
        <Row form>
            <Col md={6}>
                Single Image Template <br/>
                <img src={'api/Products/' + sku + '/SingleImageTemplate?' + imageKey} alt="" width="400px" height="400px" />
            </Col>
            <Col md={6}>
                <FormGroup>
                    <Label for="name">Template File</Label>
                    <Input type="file" name="file" id="template-file" onChange={async (e) => {
                        if (e.target.files) {
                            const file = e.target.files[0];
                            await uploadImage(file);
                        }
                    }} />
                </FormGroup>
            </Col>
        </Row>;
    const step4 =
        <>
            <Form>
                <FormGroup>
                    <Label for="productType">Product Type</Label>
                    <Input type="select" name="productType" id="productType" value={productDetails.productType} onChange={(e) => onchange(e)} valid={validation.productType} invalid={!validation.productType} >
                        <option value="1">Standard Product</option>
                        <option value="2">Personalisable Product</option>
                        <option value="3">Single Image Product</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="name">Name</Label>
                    <Input type="text" name="name" id="name" placeholder="Geeky birthday card for mother in English" value={productDetails.name} onChange={(e) => onchange(e)} valid={validation.name} invalid={!validation.name} />
                </FormGroup>
                <FormGroup>
                    <Label for="slug">Slug</Label>
                    <Input type="text" name="slug" id="slug" value={productDetails.slug} readOnly={true} />
                </FormGroup>
                <Row form>
                    <Col md={6}>
                        <img src={frontDisplayImage} width="375px" height="400px" alt="" />
     
                    </Col>
                    <Col md={6}>
                        <img src={insideDisplayImage} width="375px" height="400px" alt="" />
                    </Col>
                </Row>
                {productDetails.productType === "3" ? singleImageProductInputs : null}
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="outsideText">Outside Text</Label>
                            <Input type="textarea" name="outsideText" id="outsideText" placeholder="Happy Geeky Birthday Mom!" value={productDetails.outsideText} onChange={(e) => onchange(e)} valid={validation.outsideText} invalid={!validation.outsideText} />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="insideText">Inside Text</Label>
                            <Input type="textarea" name="insideText" id="insideText" placeholder="Happy Geeky Birthday Mom!" value={productDetails.insideText} onChange={(e) => onchange(e)} valid={validation.insideText} invalid={!validation.insideText} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="language">Language</Label>
                            <Input type="select" name="language" id="language" value={productDetails.language} onChange={(e) => onchange(e)} valid={validation.language} invalid={!validation.language} >
                                <option value="english">English</option>
                                <option value="maltese">Maltese</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="primaryCategory">Primary Category</Label>

                            <Input type="select" name="primaryCategory" id="primaryCategory" value={productDetails.primaryCategory} onChange={(e) => onchange(e)} valid={validation.primaryCategory} invalid={!validation.primaryCategory} >
                                <option>Select One</option>
                                {categories.map(c => <option key={c.id} value={c.id}>{c.selectName}</option>)}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="price">Price</Label>
                            <Input name="price" id="price" type="number" min={0} step={0.01} value={productDetails.price} onChange={(e) => onchange(e)} valid={validation.price} invalid={!validation.price} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <OtherCategoryEditor
                        primaryCategory={productDetails.primaryCategory}
                        categories={categories}
                        selectedOtherCategories={productDetails.otherCategories}
                        addOtherCategory={addOtherCategory}
                        removeOtherCategory={removeOtherCategory}
                    />
                    <Col md={4}>
                        <FormGroup>
                            <Label for="tags">Add Tag</Label>

                            <InputGroup>
                                <Input type="text" name="newTag" id="newTag" value={tagToAdd} onChange={(e) => setTagToAdd(e.target.value)} />

                                <Button color="primary" onClick={addTag}>Add</Button>

                            </InputGroup>
                        </FormGroup>
                        <ul>
                            {productDetails?.tags?.map(c => <li key={c.slug}>{c.name} <Button color="danger" size="sm" onClick={() => removeTag(c.slug)}>Remove</Button></li>)}
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        {productDetails.productType === "2" ? personalisableProductSettings : null}
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Button color="primary" disabled={!validation.isValid() || saving} onClick={() => addProductClicked()}>Add Product</Button>
                    </Col>
                </Row>
            </Form>
        </>
    return (<>
        <PlainHeader />
        <Container className="mt--7" fluid>
            <Row>
                <div className="col">

                    <Card className="shadow">
                        <CardHeader className="bg-transparent">
                            <Row className="align-contents-centre">
                                <div className="col">
                                    <h3 className="mb-0">Add Product - {sku}</h3>
                                </div>
                                <div className="col">
                                    <Nav className="justify-content-end" pills>
                                        <Button color="danger" size="md" disabled={step === 1 || saving} onClick={() => setStep(prev => prev - 1)}>Back</Button>
                                        <Button color="primary" size="md" disabled={step !== 3} onClick={() => setStep(4)}>Next</Button>
                                    </Nav>
                                </div>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            {step === 1 ? step1 : null}
                            {step === 2 ? step2 : null}
                            {step === 3 ? step3 : null}
                            {step === 4 ? step4 : null}
                        </CardBody>
                    </Card>
                </div>
            </Row>
        </Container>
    </>);
};


export default AddProduct;