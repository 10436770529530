import React, { useState, useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import { MsalProvider, } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";

import AdminLayout from "./layouts/Admin.js";

const App = (props) => {

    const [configuration, setConfiguration] = useState(null);

    useEffect(() => {
        axios.get(
            '/api/Configuration/'
        ).then((result) => {
            setConfiguration(result.data);
        });
    }, []);

    const msalConfig = {
        auth: {
            clientId: configuration?.clientId,
            authority: 'https://login.microsoftonline.com/' + configuration?.tenantId,
            redirectUri: "/",
            postLogoutRedirectUri: "/"
        }
    };
    const msalInstance = new PublicClientApplication(msalConfig);
    return (configuration !== null ?
        <MsalProvider instance={msalInstance}>
            <BrowserRouter>
                <AdminLayout {...props} configuration={configuration} />
            </BrowserRouter>
        </MsalProvider>
        : <div>Loading</div>
    );
}

export default App;