import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios"
import PlainHeader from "../../components/Headers/PlainHeader.js";
import { Container, Row, Card, CardHeader, CardBody, CardImg, Spinner } from "reactstrap";
import useAcquireTokenSilent from "../../common/useAcquireTokenSilent";

const AvailableMockups = (props) => {
    const { getToken } = useAcquireTokenSilent();
    const [availableMockups, setAvailableMockups] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getToken(props.configuration)
            .then((token) => {
                axios.get(
                    '/api/Products/ByStatus/PreProcessed',
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    }
                ).then((result) => {
                    setAvailableMockups(result.data.map(m => m.id));
                    setLoading(false);
                });
            });
    }, [getToken, props.configuration]);

    const products = availableMockups.map(m =>
        <div className="col" key={m}>
            <Card style={{ width: "14rem" }}>
                <CardImg
                    alt="..."
                    src={props.configuration.mockupsBaseUrl + m + "/" + m + "_BG02_FB04-NB.jpg"}
                    top
                />
                <CardBody>
                    <Link to={"/Products/Add/" + m} className="btn btn-primary">Add {m}</Link>
                </CardBody>
            </Card>
        </div>);

    return (<>
        <PlainHeader />
        <Container className="mt--7" fluid>
            <Row>
                <div className="col">

                    <Card className="shadow">
                        <CardHeader className="bg-transparent">
                            <h3 className="mb-0">Add Product</h3>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                {loading ? <Spinner color="primary" /> : products}
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </Row>
        </Container>
    </>);
};


export default AvailableMockups;