import React, { useState, useEffect } from "react";
import axios from "axios"
import useAcquireTokenSilent from "../../common/useAcquireTokenSilent";
import { Buffer } from 'buffer';
import { Button } from "reactstrap";

export default function OrderRow(props) {
    const { getToken } = useAcquireTokenSilent();
    const i = props.orderItem;
    const orderStatus = props.orderStatus;

    const [imageData, setImageData] = useState("");
    const [imageSize, setImageSize] = useState({ width: 1, height: 1 });

    useEffect(() => {
        if (i.sku.startsWith("IGC")) {
            setImageData("");
            getToken(props.configuration)
                .then((token) => {
                    axios.get(
                        "api/Accounts/" + props.accountId + "/CustomerImages/" + i.variant,
                        {
                            headers: {
                                'Authorization': 'Bearer ' + token
                            },
                        }
                    ).then((response) => {
                        //const raw = Buffer.from(response.data).toString('base64');
                        //const data =  "data:" + response.headers["content-type"] + ";base64," + raw;
                        setImageData(response.data.imageData);

                        const img = new Image();
                        img.src = response.data.imageData;

                        img.onload = () => {
                            setImageSize({
                                width: img.naturalWidth,
                                height: img.naturalHeight,
                            });
                        };
                    });
                });
        }
    }, [props.accountId, i.variant, i.sku, getToken, props.configuration, setImageData]);
    const variantImage = () => {
        if (i.sku.startsWith("IGC") && i.additionalParameters.length > 0) {
            let decodedParameters = Buffer.from(i.additionalParameters, "base64").toString("ascii");
            if (decodedParameters === "") {
                decodedParameters = "{}";
            }
            const parsedParameters = { x: 0, y: 0, zoom: 100, rotate: 0, ...JSON.parse(decodedParameters) }

            const largestSize = Math.max(imageSize.width, imageSize.height);
            const borderWidth = largestSize === 0 ? 0 : (imageSize.width / largestSize) * parsedParameters.zoom;
            const borderHeight = largestSize === 0 ? 0 : (imageSize.height / largestSize) * parsedParameters.zoom;
            const centerX = parsedParameters.x + borderWidth / 2;
            const centerY = parsedParameters.y + borderHeight / 2;

            return (<svg viewBox="0 0 1000 1000" style={{ width: 125, height: 125 }} xmlns="http://www.w3.org/2000/svg">
                <image
                    xlinkHref={imageData}
                    x={parsedParameters.x}
                    y={parsedParameters.y}
                    width={borderWidth}
                    height={borderHeight}
                    transform={`rotate(${parsedParameters.rotate} ${centerX} ${centerY})`}
                />
                <image
                    xlinkHref={getTemplateUrlFromPrimaryImage(i.primaryImage.imageUrl)}
                    x="0"
                    y="0"
                    width="1000"
                    height="1000"
                />
                <rect
                    x="15"
                    y="15"
                    width="970"
                    height="970"
                    stroke="white"
                    strokeWidth={32}
                    fill="none"
                />
            </svg>);
        }
        else {
            return <img src={i.primaryImage.imageUrl} alt="" width="125" />;
        }
    }

    const variantText = (variantCode, sku) => {
        switch (variantCode) {
            case "STD":
                return "";
            case "REM":
                return <><strong>Remove internal text</strong><br /></>;
            default:
                if (sku.startsWith("PGC")) {
                    var variantObject = tryParseJSONObject(variantCode);
                    if (variantObject) {
                        return <ul>{Object.keys(variantObject).map(k => <li key={variantObject[k]}>{k}: <strong>{variantObject[k]}</strong></li>)}</ul>
                    } else {
                        return <>Variant not specified<br /></>;
                    }
                } else if (sku.startsWith("BPC")) {
                    return <>File: <strong>{variantCode}.ai</strong></>;
                } else if (sku.startsWith("IGC")) {
                    return <></>;
                } else {
                    return <>Variant not specified<br /></>;
                }
        }
    }
    const tryParseJSONObject = (jsonString) => {
        try {
            var o = JSON.parse(jsonString);

            // Handle non-exception-throwing cases:
            // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
            // but... JSON.parse(null) returns null, and typeof null === "object", 
            // so we must check for that, too. Thankfully, null is falsey, so this suffices:
            if (o && typeof o === "object") {
                return o;
            }
        }
        catch (e) { }

        return false;
    }
    const getTemplateUrlFromPrimaryImage = (primaryImageUrl) => {
        // Use regular expression to match the filename
        const regex = /\/[^/]*$/;
        const match = primaryImageUrl.match(regex);

        if (match) {
            console.log(match);
            // Replace the matched filename with "single-image-template.png"
            return primaryImageUrl.replace(match[0], '/single-image-template.png');
        } else {
            // Return the original URL if no filename found
            return primaryImageUrl;
        }
    }
    return (
        <tr>
            <td>SKU: <strong>{i.sku}</strong><br />{i.name}<br />{variantText(i.variant, i.sku)}{variantImage()}</td>
            <td>{i.quantity}</td>
            <td>{i.price.toFixed(2)}</td>
            <td>{(i.quantity * i.price).toFixed(2)}</td>
            {orderStatus === 9 ? <td><Button size="sm" onClick={() => props.removeProduct(i.sku, i.variant)}>X</Button></td> : null}
        </tr>);
}