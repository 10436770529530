import React, { useState, useEffect } from "react";
import { Col, FormGroup, Label, Input, Button } from "reactstrap";

export default function OtherCategoryEditor(props) {
    const [categoryFilter, setCategoryFilter] = useState('');

    useEffect(() => {
        const primaryCategoryFilter = props.categories.filter(x => x.id === props.primaryCategory);
        const parentCategoryId = primaryCategoryFilter.length > 0 ? primaryCategoryFilter[0].parentCategoryId : '';
        const parentCategoryFilter = props.categories.filter(x => x.id === parentCategoryId);
        const parentCateogryText = parentCategoryFilter.length > 0 ? parentCategoryFilter[0].shortName : '';
        setCategoryFilter(parentCateogryText);
    }, [props.categories, props.primaryCategory, setCategoryFilter]);

    function addOtherCategory(categoryId) {
        props.addOtherCategory(categoryId);
    }

    function removeOtherCategory(categoryId) {
        props.removeOtherCategory(categoryId);
    }
    console.log(props.categories);
    return <>
        <Col md={4}>
            <FormGroup>
                <Label for="otherCategory">Add Other Category</Label>

                <Input type="text" nane="categoryFilter" value={categoryFilter} onChange={(e) => setCategoryFilter(e.target.value)} />

            </FormGroup>
            <ul>
                {props.categories.filter(c => c.selectName.toLowerCase().includes(categoryFilter.toLowerCase()) && c.id !== props.primaryCategory && props.selectedOtherCategories.filter(sc => c.id === sc).length === 0).slice(0, 20).map(c => <li key={c.id}>{c.selectName} <Button size="sm" color="primary" onClick={() => addOtherCategory(c.id)}>Add</Button></li>)}
            </ul>
  
        </Col>
        <Col md={4}>
            <FormGroup>
                <Label >Selected Other Categories</Label>
                <ul>
                {props.selectedOtherCategories.map(c => <li key={c}>{props.categories.filter(x => x.id === c)[0]?.name ?? 'Missing Category - Contact System Administrator'} <Button color="danger" size="sm" onClick={() => removeOtherCategory(c)}>Remove</Button></li>)}
                </ul>
            </FormGroup>
        </Col>
    </>;
}