import React, { useCallback, useEffect, useState } from "react";
import axios from "axios"
import PlainHeader from "../../components/Headers/PlainHeader.js";
import { Container, Row, Card, CardHeader, CardBody, Table, Spinner } from "reactstrap";

import useAcquireTokenSilent from "../../common/useAcquireTokenSilent";

const ProductList = (props) => {
    const { getToken } = useAcquireTokenSilent();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    console.log({ loading, products });
    const getData = useCallback(() => {
        setLoading(true);
        getToken(props.configuration)
            .then((token) => {
                axios.get(
                    '/api/Products/Trending',
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    }
                ).then((result) => {
                    console.log(result);
                    setProducts(result.data.results);
                    setLoading(false);
                });
            });
    }, [getToken, props.configuration]);


    useEffect(() => {
        getData();
    }, [getData]);


    return (<>
        <PlainHeader />
        <Container className="mt--7" fluid>
            <Row>
                <div className="col">
                    <Card className="shadow">
                        <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                                <div className="col">
                                    <h3 className="mb-0">Trending Products</h3>
                                </div>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">SKU</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Recent Views</th>
                                        <th scope="col">Recent Sales</th>
                                        <th scope="col">Total Sales</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? <Spinner color="primary" /> : products.map(p =>
                                        <tr key={p.id}>
                                            <th scope="row">{p.id}</th>
                                            <td>{p.name}</td>
                                            <td>{p.recentViews}</td>
                                            <td>{p.recentSales}</td>
                                            <td>{p.totalSales}</td>
                                        </tr>)}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </div>
            </Row>
        </Container>
    </>);
};


export default ProductList;