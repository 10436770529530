import React from "react";
import { Container, Row, Col } from "reactstrap";

const PlainHeader = () => {
    return (
        <>
            <div className="header bg-gradient-secondary pb-8 pt-5 pt-md-8">
                <Container fluid>
                    <div className="header-body">
                        <Row>
                            <Col lg="6" xl="3">
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>);
};

export default PlainHeader;