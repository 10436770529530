import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios"
import PlainHeader from "../../components/Headers/PlainHeader.js";
import { Container, Row, Col, Card, CardHeader, CardBody, InputGroup, Nav, Form, FormGroup, Label, Input, Button, Table } from "reactstrap";
import useAcquireTokenSilent from "../../common/useAcquireTokenSilent";
import OtherCategoryEditor from "../../components/Products/OtherCategoryEditor";

const EditProduct = (props) => {
    const { sku } = useParams();
    const { getToken } = useAcquireTokenSilent();
    const [saving, setSaving] = useState(false);
    const [productDetails, setProductDetails] = useState({});
    const [tagToAdd, setTagToAdd] = useState('');

    const validation = {
        name: productDetails?.name?.length > 0,
        insideText: productDetails?.insideText?.length > 0,
        outsideText: productDetails?.outsideText?.length > 0,
        language: productDetails?.language?.length > 0,
        primaryCategory: productDetails?.primaryCategory?.length > 0,
        price: productDetails?.price > 0,
        productType: productDetails?.productType === '1' || productDetails?.productType === '2' || productDetails?.productType === '3',
        personalisableSettings: productDetails?.productType === '1' || productDetails?.productType === '3' || (productDetails?.productType === '2' && productDetails?.personalizedFieldSettings?.length > 0),
        isValid: function () {
            return this.name && this.insideText && this.outsideText
                && this.language && this.primaryCategory && this.price
                && this.productType && this.personalisableSettings;
        }
    };
    const [newPersonalisableSetting, setNewPersonalisableSetting] = useState({
        code: '',
        name: '',
        maximumLength: 30
    });
    const newPersonalisableSettingValidation = {
        code: newPersonalisableSetting.code.length > 0 && productDetails.personalizedFieldSettings.filter(x => x.code === newPersonalisableSetting.code).length === 0,
        name: newPersonalisableSetting.name.length > 0,
        maximumLength: newPersonalisableSetting.maximumLength > 0,
        isValid: function () {
            return this.code && this.name && this.maximumLength;
        }
    }
    const [categories, setCategories] = useState([]);

    const getSubCategories = useCallback((parent, allCategories, dashes) => {
        var newSubCategories = [];

        allCategories.forEach(sc => {
            if (sc.parentCategoryId === parent.id) {
                newSubCategories.push({ ...sc, selectName: dashes + sc.name });
                getSubCategories(sc, allCategories, dashes + '- ').forEach(nc => newSubCategories.push(nc));
            }
        });
        return newSubCategories;
    }, []);

    const addOtherCategory = (categoryToAdd) => {
        setProductDetails(prev => {
            var newState = { ...prev };
            newState.otherCategories.push(categoryToAdd);
            return newState;
        });
    }

    const removeOtherCategory = (id) => {
        setProductDetails(prev => {
            var newState = { ...prev };
            newState.otherCategories = newState.otherCategories.filter(x => x !== id);
            return newState;
        });
    }
    const addTag = () => {
        setProductDetails(prev => {
            var newState = { ...prev };
            newState.tags.push({ slug: slugify(tagToAdd), name: tagToAdd });
            return newState;
        });
        setTagToAdd('');
    }
    const removeTag = (slug) => {
        setProductDetails(prev => {
            var newState = { ...prev };
            newState.tags = newState.tags.filter(x => x.slug !== slug);
            return newState;
        });
    }

    useEffect(() => {
        getToken(props.configuration)
            .then((token) => {
                axios.get(
                    '/api/Products/' + sku,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    }
                ).then((result) => {
                    var existingProduct = result.data;

                    if (existingProduct.productStatus !== 1) {
                        alert('Product does not exist!');
                        props.history.push("/");
                    } else {
                        const productDetails = JSON.parse(JSON.stringify(result.data));
                        productDetails.productType = productDetails.productType.toString();
                        setProductDetails(productDetails);
                    }
                });

                axios.get(
                    '/api/Categories/',
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    }
                ).then((result) => {
                    var categoryTree = [];

                    result.data.sort((a, b) => a.shortName.localeCompare(b.shortName));

                    result.data.forEach(c => {
                        if (c.parentCategoryId === null) {
                            categoryTree.push({ ...c, selectName: c.name });
                            getSubCategories(c, result.data, ' - ').forEach(nc => categoryTree.push(nc));
                        }
                    });
                    setCategories(categoryTree);
                });
            });
    }, [sku, getToken, props.configuration, props.history, getSubCategories]);

    const saveProductClicked = () => {

        if (validation.isValid()) {
            setSaving(true);
            productDetails.description = productDetails.name;
            getToken(props.configuration)
                .then((token) => {
                    axios({
                        method: "PUT",
                        url: "/api/products/" + sku,
                        data: { ...productDetails, sku, },
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': 'Bearer ' + token
                        }
                    })
                        .then(function (response) {
                            props.history.push('/');
                        })
                        .catch(function (response) {
                            alert('Error saving product!');
                            setSaving(false);
                        });
                });
        }
    }

    const onchange = e => {
        const field = e.target.id;
        const value = e.target.value;
        setProductDetails(prev => {
            var newState = { ...prev };
            if (field === "price") {
                newState[field] = Number(value);
            } else {
                newState[field] = value;
            }
            if (field === "productType") {
                switch (value) {
                    case "1":
                        newState.price = 3.50;
                        break;
                    case "2":
                        newState.price = 5.00;
                        break;
                    case "3":
                        newState.price = 5.50;
                        break;
                    default:
                        break;
                }
            }
            return newState;
        });
    }

    const addPersonalisedSetting = e => {
        setProductDetails(prev => {
            var newState = { ...prev };
            newState.personalizedFieldSettings.push(newPersonalisableSetting);
            return newState;
        });
        setNewPersonalisableSetting(
            {
                code: '',
                name: '',
                maximumLength: 30
            });
    }

    const removePersonalisedSetting = code => {
        setProductDetails(prev => {
            var newState = { ...prev };
            for (var i = 0; i < newState.personalizedFieldSettings.length; i++) {
                if (newState.personalizedFieldSettings[i].code === code) {
                    newState.personalizedFieldSettings.splice(i, 1);
                }
            }
            return newState;
        });
    }

    const onNewPersonalisableSettingChange = e => {
        const field = e.target.id;
        const value = e.target.value;
        setNewPersonalisableSetting(prev => {
            var newState = { ...prev };
            if (field === "maximumLength") {
                newState[field] = Number(value);
            } else {
                newState[field] = value;
            }
            return newState;
        });
    }
    const slugify = (string) => {
        const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
        const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
        const p = new RegExp(a.split('').join('|'), 'g')

        return string.toString().toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
            .replace(/&/g, '-and-') // Replace & with 'and'
            .replace(/[^\w-]+/g, '') // Remove all non-word characters
            .replace(/--+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, '') // Trim - from end of text
    }

    const personalisableProductSettings =
        <Table className="align-items-center table-flush" responsive size="sm">
            <thead className="thead-light">
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Code</th>
                    <th scope="col">Name</th>
                    <th scope="col">Maximum Length</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {productDetails?.personalizedFieldSettings?.map(i =>
                    <tr key={i.code}>
                        <td>
                            <i className="ni ni-ruler-pencil" />
                        </td>
                        <td>
                            {i.code}
                        </td>
                        <td>
                            {i.name}
                        </td>
                        <td>
                            {i.maximumLength}
                        </td>
                        <td>
                            <Button color="primary" disabled={saving} onClick={() => removePersonalisedSetting(i.code)}>Remove</Button>
                        </td>
                    </tr>
                )}
                <tr>
                    <td>
                        <i className="ni ni-fat-add" />
                    </td>
                    <td>
                        <Input type="text" name="code" id="code" placeholder="Code" value={newPersonalisableSetting.code} onChange={(e) => onNewPersonalisableSettingChange(e)} valid={newPersonalisableSettingValidation.code} invalid={!newPersonalisableSettingValidation.code} />
                    </td>
                    <td>
                        <Input type="text" name="name" id="name" placeholder="Name" value={newPersonalisableSetting.name} onChange={(e) => onNewPersonalisableSettingChange(e)} valid={newPersonalisableSettingValidation.name} invalid={!newPersonalisableSettingValidation.name} />
                    </td>
                    <td>
                        <Input type="number" name="maximumLength" id="maximumLength" placeholder="30" value={newPersonalisableSetting.maximumLength} onChange={(e) => onNewPersonalisableSettingChange(e)} valid={newPersonalisableSettingValidation.maximumLength} invalid={!newPersonalisableSettingValidation.maximumLength} />
                    </td>
                    <td>
                        <Button color="primary" disabled={!newPersonalisableSettingValidation.isValid() || saving} onClick={() => addPersonalisedSetting()}>Add</Button>
                    </td>
                </tr>
            </tbody>
        </Table>;

    const step4 =
        <>
            <Form>
                <FormGroup>
                    <Label for="productType">Product Type</Label>
                    <Input type="select" name="productType" id="productType" disabled={true} value={productDetails.productType} onChange={(e) => onchange(e)} valid={validation.productType} invalid={!validation.productType} >
                        <option value="1">Standard Product</option>
                        <option value="2">Personalisable Product</option>
                        <option value="2">Single Image Product</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="name">Name</Label>
                    <Input type="text" name="name" id="name" placeholder="Geeky birthday card for mother in English" value={productDetails.name} onChange={(e) => onchange(e)} valid={validation.name} invalid={!validation.name} />
                </FormGroup>
                <Row form>
                    <Col md={12}>
                        <img src={productDetails?.primaryImage?.imageUrl} height="400px" alt="" />
                    </Col>
                </Row>
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="outsideText">Outside Text</Label>
                            <Input type="textarea" name="outsideText" id="outsideText" placeholder="Happy Geeky Birthday Mom!" value={productDetails.outsideText} onChange={(e) => onchange(e)} valid={validation.outsideText} invalid={!validation.outsideText} />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="insideText">Inside Text</Label>
                            <Input type="textarea" name="insideText" id="insideText" placeholder="Happy Geeky Birthday Mom!" value={productDetails.insideText} onChange={(e) => onchange(e)} valid={validation.insideText} invalid={!validation.insideText} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="language">Language</Label>
                            <Input type="select" name="language" id="language" value={productDetails.language} onChange={(e) => onchange(e)} valid={validation.language} invalid={!validation.language} >
                                <option value="english">English</option>
                                <option value="maltese">Maltese</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="primaryCategory">Primary Category</Label>

                            <Input type="select" name="primaryCategory" id="primaryCategory" value={productDetails.primaryCategory} onChange={(e) => onchange(e)} valid={validation.primaryCategory} invalid={!validation.primaryCategory} >
                                <option>Select One</option>
                                {categories.map(c => <option key={c.id} value={c.id}>{c.selectName}</option>)}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="price">Price</Label>
                            <Input name="price" id="price" type="number" min={0} step={0.01} value={productDetails.price} onChange={(e) => onchange(e)} valid={validation.price} invalid={!validation.price} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <OtherCategoryEditor
                        primaryCategory={productDetails.primaryCategory}
                        categories={categories}
                        selectedOtherCategories={productDetails.otherCategories}
                        addOtherCategory={addOtherCategory}
                        removeOtherCategory={removeOtherCategory}
                    />
                    <Col md={4}>
                        <FormGroup>
                            <Label for="tags">Add Tag</Label>

                            <InputGroup>
                            <Input type="text" name="newTag" id="newTag" value={tagToAdd} onChange={(e) => setTagToAdd(e.target.value)} />                           

                                <Button color="primary" onClick={addTag}>Add</Button>

                                </InputGroup>
                        </FormGroup>
                        <ul>
                            {productDetails?.tags?.map(c => <li key={c.slug}>{c.name} <Button color="danger" size="sm" onClick={() => removeTag(c.slug)}>Remove</Button></li>)}
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        {productDetails.productType === "2" ? personalisableProductSettings : null}
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Button color="primary" disabled={!validation.isValid() || saving} onClick={() => saveProductClicked()}>Save Changes</Button>
                    </Col>
                </Row>
            </Form>
        </>
    return (<>
        <PlainHeader />
        <Container className="mt--7" fluid>
            <Row>
                <div className="col">

                    <Card className="shadow">
                        <CardHeader className="bg-transparent">
                            <Row className="align-contents-centre">
                                <div className="col">
                                    <h3 className="mb-0">Add Product - {sku}</h3>
                                </div>
                                <div className="col">
                                    <Nav className="justify-content-end" pills>
                                    </Nav>
                                </div>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            {productDetails?.productType != null ? step4 : null}
                        </CardBody>
                    </Card>
                </div>
            </Row>
        </Container>
    </>);
};


export default EditProduct;