import React, { useEffect, useState } from "react";
import { useParams, Link } from 'react-router-dom';
import PlainHeader from "../../components/Headers/PlainHeader.js";
import { Container, Row, Col, Card, Label, Input, CardHeader, CardBody, CardTitle, CardSubtitle, CardText, CardImg, Table, Nav, NavItem, Button, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Alert } from "reactstrap";
import OrderRow from "../../components/Orders/OrderRow"
import axios from "axios"
import useAcquireTokenSilent from "../../common/useAcquireTokenSilent";

const OrderDetails = (props) => {
    const { orderId } = useParams();
    const { getToken } = useAcquireTokenSilent();
    const [orderDetails, setOrderDetails] = useState({});
    const [accountDetails, setAccountDetails] = useState({});
    const [accountTotalOrders, setAccountTotalOrders] = useState(0);
    const [screenMode, setScreenMode] = useState("DetailsScreen");
    const [updateAddressModalData, setUpdateAddressModalData] = useState({});
    const [allProducts, setAllProducts] = useState([]);
    const [addItemModalData, setAddItemModalData] = useState({ productSearchFilter: '' });
    const [addBespokeItemModalData, setAddBespokeItemModalData] = useState({ variant: '' });

    const filteredProducts = allProducts
        .filter(f => f.id.toUpperCase().includes(addItemModalData.productSearchFilter.toUpperCase())
            || f.name.toUpperCase().includes(addItemModalData.productSearchFilter.toUpperCase()));

    useEffect(() => {
        if (orderId !== undefined) {
            setOrderDetails({});

            getToken(props.configuration)
                .then((token) => {
                    axios.get(
                        '/api/Orders/' + orderId,
                        {
                            headers: {
                                'Authorization': 'Bearer ' + token
                            }
                        }
                    ).then((result) => {
                        setOrderDetails(result.data.orderDetails);
                        setAccountDetails(result.data.accountDetails);
                        setAccountTotalOrders(result.data.accountTotalOrders);
                    });
                });
        }
    }, [orderId, getToken, props.configuration]);

    var actionButton = null;
    switch (orderDetails.orderStatus) {
        case 1:
        case 7:
        case 10:
            actionButton = (<Button color="primary" onClick={() => updateStatus(2)}>Set to In Progress</Button>);
            break;
        case 2:
            actionButton = (<Button color="primary" onClick={() => updateStatus(3)}>Set to Shipped</Button>);
            break;
        case 3:
            actionButton = (<Button color="secondary" onClick={() => reprintOrder()}>Reprint Order</Button>);
            break;
        case 6:
            actionButton =
                <>
                    <Button color="primary" onClick={() => updateStatus(7)}>Set to Reviewed</Button>
                    <Button color="secondary" onClick={() => updateStatus(8)}>Set to Manual Internvention</Button>
                </>;
            break;
        case 8:
            actionButton = <Button color="primary" onClick={() => updateStatus(7)}>Set to Reviewed</Button>;
            break;
        case 9: actionButton =
            <>
                <Button color="secondary" onClick={() => startUpdateAddress()}>Update Address</Button>
                <Button color="secondary" onClick={() => startAddItem()}>Add Item</Button>
                <Button color="secondary" onClick={() => startAddBespokeItem()}>Add BespokeItem</Button>
                <Button color="secondary" onClick={() => setScreenMode("AddDiscount")}>Add Discount</Button>
                {orderDetails.orderTotal > 0 ? <Button color="primary" onClick={() => updateStatus(10)}>Set to Ready For Processing</Button> : null}
                <Button color="secondary" onClick={() => updateStatus(5)}>Cancel Order</Button>
            </>;
            break;
        default:
            break;
    }

    const updateStatus = (newStatus) => {
        getToken(props.configuration)
            .then((token) => {
                axios(
                    {
                        method: "POST",
                        url: '/api/Orders/' + orderId + "/Status",
                        data: { NewOrderStatus: newStatus },
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': 'Bearer ' + token
                        }
                    }
                ).then((result) => {
                    axios.get(
                        '/api/Orders/' + orderId,
                        {
                            headers: {
                                'Authorization': 'Bearer ' + token
                            }
                        }
                    ).then((result) => {
                        setOrderDetails(result.data.orderDetails);
                        setAccountDetails(result.data.accountDetails);
                        setAccountTotalOrders(result.data.accountTotalOrders);
                    });
                });
            });
    }

    const startUpdateAddress = () => {
        setUpdateAddressModalData(orderDetails.recipientDetails);
        setScreenMode("UpdateAddress");
    }

    const updateAddressChange = e => {
        const field = e.target.name;
        const value = e.target.value;
        setUpdateAddressModalData(prev => {
            var newState = { ...prev };
            newState[field] = value;
            return newState;
        });
    }

    const completeUpdateAddress = () => {

        getToken(props.configuration)
            .then((token) => {
                axios(
                    {
                        method: "POST",
                        url: '/api/Orders/' + orderId + "/UpdateAddress",
                        data: updateAddressModalData,
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': 'Bearer ' + token
                        }
                    }
                ).then((updateAddressResult) => {
                    axios.get(
                        '/api/Orders/' + orderId,
                        {
                            headers: {
                                'Authorization': 'Bearer ' + token
                            }
                        }
                    ).then((result) => {
                        setOrderDetails(result.data.orderDetails);
                        setAccountDetails(result.data.accountDetails);
                        setAccountTotalOrders(result.data.accountTotalOrders);
                        setScreenMode("OrderDetails");
                    });
                });
            });
    }

    console.log({ allProducts, addItemModalData });

    const startAddItem = () => {
        setAddItemModalData({
            productSearchFilter: '',
            selectedProduct: null,
            quantity: 1,
            removeInternalText: false
        });
        if (allProducts.length === 0) {
            getToken(props.configuration)
                .then((token) => {
                    axios(
                        {
                            method: "GET",
                            url: '/api/Products',
                            headers: {
                                "Content-Type": "application/json",
                                'Authorization': 'Bearer ' + token
                            }
                        }
                    ).then((allProductsResult) => {
                        setAllProducts(allProductsResult.data);
                        setScreenMode("AddItem");
                    });
                });
        } else {
            setScreenMode("AddItem");
        }
    }
    const updateProductSearchValue = value => {
        setAddItemModalData(prev => {
            var newState = { ...prev };
            newState.productSearchFilter = value;
            return newState;
        });
    }
    const setQuantity = value => {
        setAddItemModalData(prev => {
            var newState = { ...prev };
            newState.quantity = value;
            return newState;
        });
    }
    const changeVariant = () => {
        setAddItemModalData(prev => {
            var newState = { ...prev };
            newState.removeInternalText = !newState.removeInternalText;
            return newState;
        });
    }
    const setSelectedProduct = value => {
        setAddItemModalData(prev => {
            var newState = { ...prev };
            newState.selectedProduct = value;
            newState.customFieldValues = {};
            newState.selectedProduct.personalizedFieldSettings.forEach(s => newState.customFieldValues[s.code] = '');
            return newState;
        });
    }

    const setCustomFieldValue = (key, value) => {
        setAddItemModalData(prev => {
            var newState = { ...prev };
            newState.customFieldValues[key] = value;
            return newState;
        });
    }

    const addProduct = (sku, variantText, quantity) => {
        getToken(props.configuration)
            .then((token) => {
                axios(
                    {
                        method: "POST",
                        url: '/api/Orders/' + orderId + "/OrderItems",
                        data: {
                            Sku: sku,
                            Variant: variantText,
                            Quantity: quantity
                        },
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': 'Bearer ' + token
                        }
                    }
                ).then((addOrderItemResult) => {
                    axios.get(
                        '/api/Orders/' + orderId,
                        {
                            headers: {
                                'Authorization': 'Bearer ' + token
                            }
                        }
                    ).then((result) => {
                        setOrderDetails(result.data.orderDetails);
                        setAccountDetails(result.data.accountDetails);
                        setAccountTotalOrders(result.data.accountTotalOrders);
                        setScreenMode("OrderDetails");
                    });
                });
            });
    }
    const addStandardProduct = (sku) => {
        addProduct(sku, addItemModalData.removeInternalText ? "REM" : "STD", addItemModalData.quantity);
    }

    const addPersonalisedProduct = () => {
        const sku = addItemModalData.selectedProduct.id;
        const variantText = JSON.stringify(addItemModalData.customFieldValues);

        addProduct(sku, variantText, addItemModalData.quantity);
    }

    const removeProduct = (sku, variant) => {
        getToken(props.configuration)
            .then((token) => {
                axios(
                    {
                        method: "DELETE",
                        url: '/api/Orders/' + orderId + "/OrderItems/" + sku + "/" + variant,
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': 'Bearer ' + token
                        }
                    }
                ).then((removeProductResult) => {
                    axios.get(
                        '/api/Orders/' + orderId,
                        {
                            headers: {
                                'Authorization': 'Bearer ' + token
                            }
                        }
                    ).then((result) => {
                        setOrderDetails(result.data.orderDetails);
                        setAccountDetails(result.data.accountDetails);
                        setAccountTotalOrders(result.data.accountTotalOrders);
                        setScreenMode("OrderDetails");
                    });
                });
            });
    }
    const startAddBespokeItem = () => {
        setAddBespokeItemModalData({
            sku: "BPC-SQ1I-MATT",
            quantity: 1,
            price: 5.50,
            variant: ''
        });
        setScreenMode("AddBespokeItem");
    }

    const udpateBespokeProduct = e => {
        const field = e.target.name;
        const value = e.target.value;
        setAddBespokeItemModalData(prev => {
            var newState = { ...prev };
            newState[field] = field !== "variant" ? value : slugify(value);
            return newState;
        });
    }
    const completeAddBespokeProduct = () => {
        getToken(props.configuration)
            .then((token) => {
                axios(
                    {
                        method: "POST",
                        url: '/api/Orders/' + orderId + "/BespokeOrderItems",
                        data: {
                            Sku: addBespokeItemModalData.sku,
                            Variant: addBespokeItemModalData.variant,
                            Quantity: addBespokeItemModalData.quantity,
                            Price: addBespokeItemModalData.price
                        },
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': 'Bearer ' + token
                        }
                    }
                ).then((addOrderItemResult) => {
                    axios.get(
                        '/api/Orders/' + orderId,
                        {
                            headers: {
                                'Authorization': 'Bearer ' + token
                            }
                        }
                    ).then((result) => {
                        setOrderDetails(result.data.orderDetails);
                        setAccountDetails(result.data.accountDetails);
                        setAccountTotalOrders(result.data.accountTotalOrders);
                        setScreenMode("OrderDetails");
                    });
                });
            });
    }
    const reprintOrder = () => {
        getToken(props.configuration)
            .then((token) => {
                axios(
                    {
                        method: "POST",
                        url: '/api/Orders/' + orderId + "/Reprint",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': 'Bearer ' + token
                        }
                    }
                ).then((result) => {
                    props.history.push('/orders/InProgress');
                });
            });
    }

    const orderStatusText = status => {
        /*Pending = 0,
        PaymentReceived = 1,
        InProgress = 2,
        Shipped = 3,
        Delayed = 4,
        Canceled = 5,
        RequiresReview = 6.
        Reviewed = 7*/
        switch (status) {
            case 0:
                return (<Badge color="" className="badge-dot mr-4">
                    <i className="bg-warning" />
                    Pending
                </Badge>);
            case 1:
                return (<Badge color="" className="badge-dot mr-4">
                    <i className="bg-info" />
                    Payment Received
                </Badge>);
            case 2:
                return (<Badge color="" className="badge-dot mr-4">
                    <i className="bg-info" />
                    In Progress
                </Badge>);
            case 3:
                return (<Badge color="" className="badge-dot mr-4">
                    <i className="bg-success" />
                    Shipped
                </Badge>);
            case 6:
                return (<Badge color="" className="badge-dot mr-4">
                    <i className="bg-warning" />
                    Requires Review
                </Badge>);
            case 7:
                return (<Badge color="" className="badge-dot mr-4">
                    <i className="bg-info" />
                    Reviewed
                </Badge>);
            case 8:
                return (<Badge color="" className="badge-dot mr-4">
                    <i className="bg-warning" />
                    Manual Intervention
                </Badge>);
            case 9:
                return (<Badge color="" className="badge-dot mr-4">
                    <i className="bg-warning" />
                    Creating
                </Badge>);
            case 10:
                return (<Badge color="" className="badge-dot mr-4">
                    <i className="bg-info" />
                    Ready for Processing
                </Badge>);
            default:
                return (status);
        }
    }

    

    const slugify = (string) => {
        const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
        const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
        const p = new RegExp(a.split('').join('|'), 'g')

        return string.toString().toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
            .replace(/&/g, '-and-') // Replace & with 'and'
            .replace(/[^\w-]+/g, '') // Remove all non-word characters
            .replace(/--+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
    }

    return (<>
        <PlainHeader />
        <Container className="mt--7" fluid>
            <Row>
                <div className="col">
                    <Card className="shadow" body>
                        <CardHeader>
                            <Row className="align-items-center">
                                <Col md={3}>
                                    <h3 className="mb-0">Order Details</h3>
                                </Col>
                                <Col md={9}>
                                    <Nav className="justify-content-end" pills>
                                        <NavItem>
                                            {actionButton}
                                        </NavItem>
                                    </Nav>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <div className="col">
                                    Order Number: <strong>{orderDetails.orderId}</strong><br />
                                    Order Status: <strong>{orderStatusText(orderDetails?.orderStatus)}</strong><br />
                                    Account: <strong><Link to={'/Accounts/' + orderDetails.accountId + '/Orders/'}>{accountDetails?.firstName} {accountDetails?.lastName} [{accountTotalOrders} orders]</Link></strong><br />
                                    Mobile Number: <strong>{accountDetails?.phoneNumber}</strong> | <strong>{orderDetails.recipientDetails?.phoneNumber}</strong><br />
                                    Promo Code: <strong>{orderDetails.promoCode}</strong><br />
                                </div>
                                <div className="col">
                                    {orderDetails.recipientDetails?.recipientName}<br />
                                    {orderDetails.recipientDetails?.addressLine1}<br />
                                    {orderDetails.recipientDetails?.addressLine2}<br />
                                    {orderDetails.recipientDetails?.town}, {orderDetails.recipientDetails?.county}, {orderDetails.recipientDetails?.postCode}<br />
                                    {orderDetails.recipientDetails?.country}
                                    <a href={"mailto://" + orderDetails.recipientEmailAddress}>{orderDetails.recipientEmailAddress}</a>
                                </div>
                                <div className="col">
                                    Payment Id: <a href={"https://dashboard.stripe.com/payments/" + orderDetails.paymentDetails?.stripeId} target="_blank" rel="noreferrer">{orderDetails.paymentDetails?.stripeId}</a><br />
                                    Payment Amount: <strong>{orderDetails.paymentDetails?.paymentIntentAmount?.toFixed(2)}</strong><br />
                                    Processed On: <strong>{new Date(orderDetails.paymentDetails?.paymentProcessedOn).toLocaleString()}</strong><br />
                                    Status: <strong>{orderDetails.paymentDetails?.paymentStatus}</strong>
                                </div>
                            </Row>
                            <Row>
                                <div className="col">
                                    <Table className="align-items-center table-flush" responsive size="sm">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Quantity</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">Total</th>
                                                {orderDetails.orderStatus === 9 ? <th scope="col"></th> : null}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderDetails.orderItems?.map(i => <OrderRow key={i.sku + i.variant} configuration={props.configuration} accountId={orderDetails.accountId} orderItem={i} orderStatus={orderDetails.orderStatus} removeProduct={removeProduct} />)}
                                            <tr>
                                                <td><strong>Subtotal</strong></td>
                                                <td></td>
                                                <td></td>
                                                <td><strong>{orderDetails.orderSubTotal?.toFixed(2)}</strong></td>
                                                {orderDetails.orderStatus === 9 ? <td></td> : null}
                                            </tr>
                                            <tr>
                                                <td>Discount</td>
                                                <td></td>
                                                <td></td>
                                                <td>- {orderDetails.discount?.toFixed(2)}</td>
                                                {orderDetails.orderStatus === 9 ? <td></td> : null}
                                            </tr>
                                            <tr>
                                                <td>{orderDetails.shippingDetails?.shippingMethod}</td>
                                                <td></td>
                                                <td></td>
                                                <td>{orderDetails.shippingDetails?.shippingCost?.toFixed(2)}</td>
                                                {orderDetails.orderStatus === 9 ? <td></td> : null}
                                            </tr>
                                            <tr>
                                                <td><strong>Total</strong></td>
                                                <td></td>
                                                <td></td>
                                                <td><strong>{orderDetails.orderTotal?.toFixed(2)}</strong></td>
                                                {orderDetails.orderStatus === 9 ? <td></td> : null}
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </Row>
        </Container>

        <Modal
            isOpen={screenMode === "UpdateAddress"}
            backdropClassName="static"
            keyboard={false} >

            <ModalHeader>
                Update Address
            </ModalHeader>
            <ModalBody>
                <Form>
                    <Row form>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="uaRecipientName">Recipient Name</Label>
                                <Input type="text" name="recipientName" id="uarecipientName" placeholder="Recipient Name" value={updateAddressModalData.recipientName} onChange={updateAddressChange} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="uaAddress1">House Number/Name</Label>
                                <Input type="text" name="addressLine1" id="uaAddress1" placeholder="House Number/Name" value={updateAddressModalData.addressLine1} onChange={updateAddressChange} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="uaAddress2">Street Name</Label>
                                <Input type="text" name="addressLine2" id="uaAddress2" placeholder="Steet Name" value={updateAddressModalData.addressLine2} onChange={updateAddressChange} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="uaCity">City</Label>
                                <Input type="text" name="town" id="uaCity" placeholder="City" value={updateAddressModalData.town} onChange={updateAddressChange} />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="uaPostCode">PostCode</Label>
                                <Input type="text" name="postCode" id="uaPostCode" placeholder="Post Code" value={updateAddressModalData.postCode} onChange={updateAddressChange} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="uaCountry">Country</Label>
                                <Input type="text" name="country" id="uaCountry" placeholder="Country" value={updateAddressModalData.country} onChange={updateAddressChange} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    onClick={() => completeUpdateAddress()}
                >
                    Update Address
                </Button>
                {' '}
                <Button onClick={() => setScreenMode("OrderDetails")}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>

        <Modal
            isOpen={screenMode === "AddItem"}
            backdropClassName="static"
            keyboard={false}
            size="xl">

            <ModalHeader>
                Add Item
            </ModalHeader>
            <ModalBody>
                {addItemModalData.selectedProduct === null ?
                    <Form>
                        <Row form>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="aiProductSearch">Search Product</Label>
                                    <Input type="text" name="aiProductSearch" id="aiProductSearch" placeholder="Product Search" value={addItemModalData.productSearchFilter} onChange={(e) => updateProductSearchValue(e.target.value)} />
                                </FormGroup>
                            </Col>
                        </Row>
                        {filteredProducts.length > 6 ?
                            <Row>
                                <Col md={12}>
                                    <Alert color="warning">More than 6 results returned.</Alert>
                                </Col>
                            </Row> : null}
                        {filteredProducts.length === 0 ?
                            <Row>
                                <Col md={12}>
                                    <Alert color="danger">No matching products.</Alert>
                                </Col>
                            </Row> : null}
                        <Row>
                            {filteredProducts.slice(0, 6).map(p =>
                                <Col md={4} key={p.id}>
                                    <Card className="shadow" body>
                                        <CardImg
                                            alt="Card Primary Image"
                                            src={p.primaryImage?.imageUrl}
                                            top
                                            width="100%"
                                        />
                                        <CardBody>
                                            <CardTitle tag="h5">{p.id}</CardTitle>
                                            <CardSubtitle className="mb-2 text-muted" tag="h6">{p.name}</CardSubtitle>
                                            <CardText></CardText>
                                            {p.productType === 1 ? <>
                                                <Row form>
                                                    <Col md={12}>
                                                        <FormGroup check inline className="small">
                                                            <Input type="checkbox" checked={addItemModalData.removeInternalText} onChange={() => changeVariant()} />
                                                            <Label check>
                                                                Remove Internal Text
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row form>
                                                    <Col md={12}>
                                                        <FormGroup inline className="small">
                                                            <Label for="aiQuantity">Quantity</Label>
                                                            <Input type="number" name="aiQUantity" id="aiQuantity" placeholder="Quantity" value={addItemModalData.quantity} onChange={(e) => setQuantity(e.target.value)} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </> : null}

                                            {p.productType === 1 ? <Button color="primary" onClick={() => addStandardProduct(p.id)}>Add</Button> : null}
                                            {p.productType === 2 ? <Button color="secondary" onClick={() => setSelectedProduct(p)}>Customise</Button> : null}
                                        </CardBody>
                                    </Card>
                                </Col>)}
                        </Row>
                    </Form> :

                    <Form>
                        <Row form>
                            {addItemModalData.selectedProduct?.personalizedFieldSettings.map(s =>
                                <Col md={6} key={s.code}>
                                    <FormGroup>
                                        <Label for={s.code}>{s.name}</Label>
                                        <Input type="text" name={s.code} id={s.code} placeholder={s.name} value={addItemModalData.customFieldValues[s.code]} onChange={(e) => setCustomFieldValue(s.code, e.target.value)} />
                                    </FormGroup>
                                </Col>)}
                        </Row>
                        <Row form>
                            <Button onClick={() => addPersonalisedProduct()}>
                                Add Product
                            </Button>
                        </Row>
                    </Form>}
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => setScreenMode("OrderDetails")}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>

        <Modal
            isOpen={screenMode === "AddBespokeItem"}
            backdropClassName="static"
            keyboard={false} >

            <ModalHeader>
                Add Item
            </ModalHeader>
            <ModalBody>
                <Form>
                    <Row form>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="abiSku">
                                    Product
                                </Label>
                                <Input id="abiSku" name="sku" type="select" value={addBespokeItemModalData.sku} onChange={udpateBespokeProduct} >
                                    <option value="BPC-SQ1I-MATT">
                                        Square - Internal One Side - Matt
                                    </option>
                                    <option value="BPC-SQ1I-GLOSS">
                                        Square - Internal One Side - Gloss
                                    </option>
                                    <option value="BPC-SQ2I-MATT">
                                        Square - Internal Both Sides - Matt
                                    </option>
                                    <option value="BPC-SQ2I-GLOSS">
                                        Square - Internal Both Sides - Gloss
                                    </option>
                                    <option value="BPC-A52I-MATT">
                                        A5 - Internal Both Sides - Matt
                                    </option>
                                    <option value="BPC-A52I-GLOSS">
                                        A5 - Internal Both Sides - Gloss
                                    </option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="abiName">Name</Label>
                                <Input type="text" name="variant" id="abiName" placeholder="Friendly Name" value={addBespokeItemModalData.variant} onChange={udpateBespokeProduct} valid={addBespokeItemModalData.variant.length > 3} invalid={addBespokeItemModalData.variant.length <= 3} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="abiQuantity">Quantity</Label>
                                <Input type="number" name="quantity" id="abiQuantity" placeholder="1" value={addBespokeItemModalData.quantity} onChange={udpateBespokeProduct} />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="abiPrice">Unit Price</Label>
                                <Input type="number" name="price" id="abiPrice" placeholder="5.50" value={addBespokeItemModalData.price} onChange={udpateBespokeProduct} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    onClick={() => completeAddBespokeProduct()}>
                    Add Bespoke Product
                </Button>
                {' '}
                <Button onClick={() => setScreenMode("OrderDetails")}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>

        <Modal
            isOpen={screenMode === "AddDiscount"}
            backdropClassName="static"
            keyboard={false} >

            <ModalHeader>
                Add Item
            </ModalHeader>
            <ModalBody>
                <Form>
                    <Row form>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="adDiscount">Discount</Label>
                                <Input type="number" name="adDiscount" id="adDiscount" placeholder="0.00" />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    onClick={() => setScreenMode("OrderDetails")}
                >
                    Do Something
                </Button>
                {' '}
                <Button onClick={() => setScreenMode("OrderDetails")}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    </>);
};


export default OrderDetails;