import React, { useEffect, useState } from "react";
import { useParams, Link } from 'react-router-dom';
import axios from "axios"
import PlainHeader from "../../components/Headers/PlainHeader.js";
import { Container, Row, Card, CardHeader, CardBody, Table, Badge, Spinner } from "reactstrap";

import useAcquireTokenSilent from "../../common/useAcquireTokenSilent";

const AccountOrderList = (props) => {
    const { accountId } = useParams();
    const { getToken } = useAcquireTokenSilent();
    const [orders, setOrders] = useState([]);
    const [accountDetails, setAccountDetails] = useState({});
    const [loading, setLoading] = useState(true);

    const getData = () => {
        setLoading(true);
        getToken(props.configuration)
            .then((token) => {
                axios.get(
                    '/api/Accounts/' + accountId + '/Orders/',
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    }
                ).then((result) => {
                    result.data.sort((a, b) => b.orderCreatedOn.localeCompare(a.orderCreatedOn));
                    setOrders(result.data);
                    setLoading(false);
                });

                axios.get(
                    '/api/Accounts/' + accountId,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    }
                ).then((result) => {
                    console.log({ r: 'r', result })
                    setAccountDetails(result.data);
                    setLoading(false);
                });
            });
    }
    useEffect(getData, []);
    
    const orderStatus = status => {
        /*Pending = 0,
        PaymentReceived = 1,
        InProgress = 2,
        Shipped = 3,
        Delayed = 4,
        Canceled = 5*/
        switch (status) {
            case 0:
                return (<Badge color="" className="badge-dot mr-4">
                    <i className="bg-warning" />
                    Pending
                </Badge>);
            case 1:
                return (<Badge color="" className="badge-dot mr-4">
                    <i className="bg-info" />
                    Payment Received
                </Badge>);
            case 2:
                return (<Badge color="" className="badge-dot mr-4">
                    <i className="bg-info" />
                    In Progress
                </Badge>);
            case 3:
                return (<Badge color="" className="badge-dot mr-4">
                    <i className="bg-success" />
                    Shipped
                </Badge>);
            default:
                return (status);
        }
    }
    return (<>
        <PlainHeader />
        <Container className="mt--7" fluid>
            <Row>
                <div className="col">
                    <Card className="shadow">
                        <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                                <div className="col">
                                    <h3 className="mb-0">{accountDetails.firstName} {accountDetails.lastName}</h3>
                                </div>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row className="align-items-center">
                                {(accountDetails.googleLoginId ?? '') !== '' ? <div className="col">
                                    Signed in with Google
                                </div> : null}
                                {(accountDetails.facebookLoginId ?? '') !== '' ? <div className="col">
                                    Signed in with Facebook
                                    <img width={100} height={100} src={'https://graph.facebook.com/v19.0/' + accountDetails.facebookLoginId + '/picture?type=normal&redirect=1'} alt="Profile"/> 
                                </div> : null}
                                {(accountDetails.microsoftLoginId ?? '') !== '' ? <div className="col">
                                    Signed in with Microsoft
                                </div> : null}
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </Row>
            <Row>
                <div className="col">
                    <Card className="shadow">
                        <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                                <div className="col">
                                    <h3 className="mb-0">Order Details</h3>
                                </div>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Order Id</th>
                                        <th scope="col">Recipient</th>
                                        <th scope="col">Created On</th>
                                        <th scope="col">No of Cards</th>
                                        <th scope="col">Order Total</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? <Spinner color="primary" /> : orders.map(o =>
                                        <tr key={o.orderId}>
                                            <th scope="row"><Link to={"/OrderDetails/" + o.orderId}>{o.orderId}</Link></th>
                                            <td>{o?.recipientDetails?.recipientName}</td>
                                            <td>{new Date(o.orderCreatedOn).toLocaleString()}</td>
                                            <td>{o.orderItems.map(a => a.quantity).reduce((a, b) => a + b, 0)}</td>
                                            <td>{o.orderTotal.toFixed(2)}</td>
                                            <td>{orderStatus(o.orderStatus)}</td>
                                        </tr>)}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </div>
            </Row>
        </Container>
    </>);
};


export default AccountOrderList;