import React, {  useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAcquireTokenSilent from "../../common/useAcquireTokenSilent";
import axios from "axios"
import PlainHeader from "../../components/Headers/PlainHeader.js";
import { Container, Row, Card, CardHeader, CardBody, CardImg, Nav, Button,  } from "reactstrap";

const ChangeImages = (props) => {
    const { sku } = useParams();
    const { getToken } = useAcquireTokenSilent();

    const [saving, setSaving] = useState(false);
    const [step, setStep] = useState(1);
    const [availableImages, setAvailableImages] = useState([]);
    const [primaryImage, setPrimaryImage] = useState(null);
    const [facebookImage, setFacebookImage] = useState(null);
    const [alternateImages, setAlternateImages] = useState([]);

    useEffect(() => {
        getToken(props.configuration)
            .then((token) => {

                axios.get(
                    '/api/Mockups/' + sku + '/Images',
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    }
                ).then((result) => {
                    setAvailableImages(result.data);
                });
            });
    }, [getToken, props.configuration, sku]);

    const clickPrimaryImage = (imageUrl) => {
        setPrimaryImage(imageUrl);
        setStep(2);

        const splitPrimaryImage = imageUrl.split('_');
        if (splitPrimaryImage.length >= 3 && !splitPrimaryImage[splitPrimaryImage.length - 1].toUpperCase().includes('NB.JPG')) {
            const backgroundCode = splitPrimaryImage[splitPrimaryImage.length - 2];
            setAlternateImages(availableImages
                .filter(f => f !== imageUrl)
                .filter(f =>
                    f.toUpperCase().endsWith(backgroundCode + "_FB01.JPG")
                    || f.toUpperCase().endsWith(backgroundCode + "_FB02.JPG")
                    || f.toUpperCase().endsWith(backgroundCode + "_FB03.JPG")
                    || f.toUpperCase().endsWith("BG02_FB04-NB.JPG")));
        }
    }

    const clickFacebookImage = (imageUrl) => {
        setFacebookImage(imageUrl);
        setStep(3);
    }

    const clickAvailableImage = (imageUrl) => {
        setAlternateImages(prev => prev.concat(imageUrl));
    }

    const clickAlternateImage = (imageUrl) => {
        setAlternateImages(prev => {
            const newArray = [];
            prev.filter(f => f !== imageUrl).forEach(f => newArray.push(f));
            return newArray;
        });
    }

    const replaceImages = () => {
        setSaving(true);
        getToken(props.configuration)
            .then((token) => {
                axios({
                    method: "POST",
                    url: "/api/products/" + sku + "/ReplaceImages",
                    data: { primaryImage, facebookImage, alternateImages },
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(function (response) {
                        props.history.push('/Products/ChangeImages');
                    })
                    .catch(function (response) {
                        alert('Error changing images!');
                        setSaving(false);
                    });
            });
    }

    const primaryImages = availableImages
        .filter(f => f.toUpperCase().endsWith('_FB01.JPG') || f.toUpperCase().endsWith('_FB02.JPG') || f.toUpperCase().endsWith('_FB04-NB.JPG'));

    const facebookImages = [];
    if (step === 2) {
        const splitPrimaryImage = primaryImage.split('_');
        const tempFacebookImages = [];
        if (splitPrimaryImage.length >= 3 && !splitPrimaryImage[splitPrimaryImage.length - 1].toUpperCase().includes('NB.JPG')) {
            const backgroundCode = splitPrimaryImage[splitPrimaryImage.length - 2];
            availableImages.filter(f => f.toUpperCase().includes(backgroundCode + '_FBL0')).forEach(i => tempFacebookImages.push(i));
            availableImages.filter(f => f.toUpperCase().includes('_FBL0') && f.toUpperCase().endsWith('NB.JPG')).forEach(i => tempFacebookImages.push(i));
        } else {
            availableImages.filter(f => f.toUpperCase().includes('_FBL0')).forEach(i => tempFacebookImages.push(i));
        }
        [...new Set(tempFacebookImages)].forEach(i => facebookImages.push(i));

    }

    const availableAlternateImages = [];
    if (step === 3) {
        const splitPrimaryImage = primaryImage.split('_');
        if (splitPrimaryImage.length >= 3 && !splitPrimaryImage[splitPrimaryImage.length - 1].toUpperCase().includes('NB.JPG')) {
            const backgroundCode = splitPrimaryImage[splitPrimaryImage.length - 2];
            availableImages
                .filter(f => f !== primaryImage && !alternateImages.includes(f))
                .filter(f =>
                    f.toUpperCase().endsWith(backgroundCode + "_FB01.JPG")
                    || f.toUpperCase().endsWith(backgroundCode + "_FB02.JPG")
                    || f.toUpperCase().endsWith(backgroundCode + "_FB03.JPG")
                    || f.toUpperCase().endsWith("BG02_FB04-NB.JPG"))
                .forEach(i => availableAlternateImages.push(i));
        } else {
            availableImages
                .filter(f => f !== primaryImage && !alternateImages.includes(f))
                .filter(f =>
                    f.toUpperCase().endsWith("_FB01.JPG")
                    || f.toUpperCase().endsWith("_FB02.JPG")
                    || f.toUpperCase().endsWith("_FB03.JPG")
                    || f.toUpperCase().endsWith("BG02_FB04-NB.JPG"))
                .forEach(i => availableAlternateImages.push(i));
        }
        availableImages
            .filter(f => f !== primaryImage && !alternateImages.includes(f) && f.toUpperCase().includes('_BG02_CM'))
            .forEach(i => availableAlternateImages.push(i));
    }

    const step1 = <>
        <h2>Select Primary Image</h2>
        <Row>
            {primaryImages
                .map(i =>
                    <div className="col" key={i}>
                        <Card style={{ width: "14rem" }}>
                            <CardImg
                                alt="..."
                                src={i}
                                top
                            />
                            <CardBody>
                                <Button color="primary" onClick={() => clickPrimaryImage(i)}>Select As Primary </Button>
                            </CardBody>
                        </Card>
                    </div>)}
        </Row>
    </>

    const step2 =
        <>
            <h2>Select Facebook Image</h2>
            <Row>
                {facebookImages
                    .map(i =>
                        <div className="col" key={i}>
                            <Card style={{ width: "14rem" }}>
                                <CardImg
                                    alt="..."
                                    src={i}
                                    top
                                />
                                <CardBody>
                                    <Button color="primary" onClick={() => clickFacebookImage(i)}>Select As Facebook Image </Button>
                                </CardBody>
                            </Card>
                        </div>)}
            </Row>
        </>

    const step3 =
        <>
            <Row>
                <div className="col-7">
                    <h2>Available Images</h2>
                    <Row>
                        {availableAlternateImages
                            .map(i =>
                                <div className="col" key={i}>
                                    <Card style={{ width: "14rem" }}>
                                        <CardImg
                                            alt="..."
                                            src={i}
                                            top
                                        />
                                        <CardBody>
                                            <Button color="success" onClick={() => clickAvailableImage(i)}>Add Image </Button>
                                        </CardBody>
                                    </Card>
                                </div>)}
                    </Row>
                </div>
                <div className="col-5">
                    <h2>Selected Images ({alternateImages.length})</h2>
                    <Row>
                        {alternateImages
                            .map(i =>
                                <div className="col" key={i}>
                                    <Card style={{ width: "14rem" }}>
                                        <CardImg
                                            alt="..."
                                            src={i}
                                            top
                                        />
                                        <CardBody>
                                            <Button color="danger" onClick={() => clickAlternateImage(i)}>Remove Image </Button>
                                        </CardBody>
                                    </Card>
                                </div>)}
                    </Row>
                </div>
            </Row>
        </>

    return (<>
        <PlainHeader />
        <Container className="mt--7" fluid>
            <Row>
                <div className="col">

                    <Card className="shadow">
                        <CardHeader className="bg-transparent">
                            <Row className="align-contents-centre">
                                <div className="col">
                                    <h3 className="mb-0">Change Images - {sku}</h3>
                                </div>
                                <div className="col">
                                    <Nav className="justify-content-end" pills>
                                        <Button color="danger" size="md" disabled={step === 1 || saving} onClick={() => setStep(prev => prev - 1)}>Back</Button>
                                        <Button color="primary" size="md" disabled={step !== 3} onClick={() => replaceImages()}>UpdateImages</Button>
                                    </Nav>
                                </div>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            {step === 1 ? step1 : null}
                            {step === 2 ? step2 : null}
                            {step === 3 ? step3 : null}
                        </CardBody>
                    </Card>
                </div>
            </Row>
        </Container>
    </>);
};


export default ChangeImages;