import { useMsal, useAccount } from "@azure/msal-react";
import { useCallback } from "react";

const useAcquireTokenSilent = () => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const getToken = useCallback((configuration, scopes) => {
        if (scopes === undefined) {
            scopes = [configuration.audience + "/user_impersonation"];
        }

        if (account) {
            return instance.acquireTokenSilent({
                scopes: scopes,
                account: account
            }).then((response) => {
                return response.accessToken;
            }
            );
        };
    }, [instance, account]);

    return {
        getToken: getToken
    };
}

export default useAcquireTokenSilent;