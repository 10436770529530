import React, { useEffect, useState } from "react";
import Header from "../components/Headers/Header.js";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import Chart from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import axios from "axios";
import useAcquireTokenSilent from "../common/useAcquireTokenSilent";
import {
    chartOptions,
    parseOptions,
} from "../common/charts.js";


const Dashboard = (props) => {
    const { getToken } = useAcquireTokenSilent();
    const [loading, setLoading] = useState(true);
    const [salesGraphData, setSalesGraphData] = useState({});
    const [ordersGraphData, setOrdersGraphData] = useState({});

    if (window.Chart) {
        parseOptions(Chart, chartOptions());
    }

    const getData = () => {
        setLoading(true);
        getToken(props.configuration)
            .then((token) => {
                axios.get(
                    '/api/Dashboards/',
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    }
                ).then((result) => {
                    let salesData = {
                        labels: [],
                        datasets: [
                            {
                                label: 'Website Sales',
                                data: [],
                                fill: true,
                                backgroundColor: 'rgb(255, 99, 132)',
                                borderColor: 'rgba(255, 99, 132, 0.2)',
                            },
                            {
                                label: 'Manual Sales',
                                data: [],
                                fill: true,
                                backgroundColor: 'rgb(0, 173, 28)',
                                borderColor: 'rgba(0, 173, 28, 0.2)',
                            },
                        ]
                    }

                    result.data.forEach(x => {
                        salesData.labels.push(x.date);
                        salesData.datasets[0].data.push(x.totalWebsiteSales);
                        salesData.datasets[1].data.push(x.totalManualSales);
                    });
                    setSalesGraphData({ data: salesData, options: salesOptions });

                    let ordersData = {
                        labels: [],
                        datasets: [
                            {
                                label: 'Website Orders',
                                data: [],
                                fill: false,
                                backgroundColor: 'rgb(255, 99, 132)',
                                borderColor: 'rgba(255, 99, 132, 0.2)',
                            },
                            {
                                label: 'Manual Orders',
                                data: [],
                                fill: false,
                                backgroundColor: 'rgb(0, 173, 28)',
                                borderColor: 'rgba(0, 173, 28, 0.2)',
                            },
                        ]
                    }
                    result.data.forEach(x => {
                        ordersData.labels.push(x.date);
                        ordersData.datasets[0].data.push(x.totalWebsiteOrders);
                        ordersData.datasets[1].data.push(x.totalManualOrders);
                    });
                    setOrdersGraphData({ data: ordersData, options: ordersOptions});

                    setLoading(false);
                });
            });
    }

    useEffect(getData, []);

    const salesOptions = {
        scales: {
            xAxes: [{
                type: 'time',
                time: {
                    unit: "day",
                    displayFormats: {
                        day: "DD MMM"
                    }
                },
                stacked: true
            }],
            yAxes: [
                {
                    gridLines: {
                        color: '#212529',
                        zeroLineColor: '#212529',
                    },
                    ticks: {
                        callback: function (value) {
                            if (value % 5 === 0) {
                                return "€ " + value;
                            }
                        },
                    },
                    stacked: true,
                },
            ],
        },
    };
    const ordersOptions = {
        scales: {
            xAxes: [{
                type: 'time',
                time: {
                    unit: "day",
                    displayFormats: {
                        day: "DD MMM"
                    }
                },
                stacked: true
            }],
            yAxes: [
                {
                    ticks: {
                        callback: function (value) {
                            if (value % 1 === 0) {
                                return value;
                            }
                        },
                    },
                    stacked: true,
                },
            ],
        },
    };
    return (loading ? <></> :
        <>
            <Header {...props} />
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="mb-5 mb-xl-0" xl="7">
                        <Card className="bg-gradient-default shadow">
                            <CardHeader className="bg-transparent">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h6 className="text-uppercase text-light ls-1 mb-1">
                                            Overview
                    </h6>
                                        <h2 className="text-white mb-0">Sales value</h2>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <div className="chart">
                                    <Line data={salesGraphData.data} options={salesGraphData.options} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col xl="5">
                        <Card className="shadow">
                            <CardHeader className="bg-transparent">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h6 className="text-uppercase text-muted ls-1 mb-1">
                                            Performance
                    </h6>
                                        <h2 className="mb-0">Total orders</h2>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <div className="chart">
                                    <Bar
                                        data={ordersGraphData.data}
                                        options={ordersGraphData.options}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>);
};

export default Dashboard;