import React, {  useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios"
import PlainHeader from "../../components/Headers/PlainHeader.js";
import { Container, Row, Card, CardHeader, CardBody, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import useAcquireTokenSilent from "../../common/useAcquireTokenSilent";

const AddCategory = (props) => {
    const { parentCategoryId } = useParams();
    const { getToken } = useAcquireTokenSilent();

    const [newCategory, setNewCategory] = useState({
        id: '',
        shortName: '',
        name: '',
        skuCode: '',
        description: '',
        parentCategoryId: parentCategoryId
    });

    const [saving, setSaving] = useState(false);

    const validation = {
        id: newCategory.id.length > 0,
        shortName: newCategory.shortName.length > 0,
        name: newCategory.name.length > 0,
        isValid: function () {
            return this.id && this.shortName && this.name;
        }
    };

    const onchange = e => {
        const field = e.target.id;
        const value = e.target.value;
        setNewCategory(prev => {
            var newState = { ...prev };
            newState[field] = value;
            return newState;
        });
    }

    const saveCategory = () => {
        setSaving(true);
        getToken(props.configuration)
            .then((token) => {
                axios({
                    method: "POST",
                    url: "/api/categories",
                    data: newCategory,
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(function (response) {
                        props.history.push('/categories');
                    })
                    .catch(function (response) {
                        alert('Error creating category!');
                        setSaving(false);
                    });
            });
    };

    return (<>
        <PlainHeader />
        <Container className="mt--7" fluid>
            <Row>
                <div className="col">
                    <Card className="shadow">
                        <CardHeader className="bg-transparent">
                            <h3 className="mb-0">Add Category {parentCategoryId !== undefined ? " - " + parentCategoryId : ""}</h3>
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <Row form>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="id">Id</Label>
                                            <Input type="text" name="id" id="id" placeholder="new-category-id" value={newCategory.id} valid={validation.id} invalid={!validation.id} onChange={(e) => onchange(e)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="shortName">Short Name</Label>
                                            <Input type="text" name="shortName" id="shortName" placeholder="Girl" value={newCategory.shortName} valid={validation.shortName} invalid={!validation.shortName} onChange={(e) => onchange(e)} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="name">Name</Label>
                                            <Input type="text" name="name" id="name" placeholder="Girl Birthday Cards" value={newCategory.name} valid={validation.name} invalid={!validation.name} onChange={(e) => onchange(e)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="skuCode">Sku Code</Label>
                                            <Input type="text" name="skuCode" id="skuCode" placeholder="BDD" value={newCategory.skuCode} onChange={(e) => onchange(e)} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Label for="description">Description</Label>
                                    <Input type="text" name="description" id="description" placeholder="Category with birthday cards for girls!" value={newCategory.description} onChange={(e) => onchange(e)} />
                                </FormGroup>
                                <Button color="primary" disabled={!validation.isValid() || saving} onClick={saveCategory}>Add Category</Button>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </Row>
        </Container>
    </>);
};


export default AddCategory;