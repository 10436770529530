import React, { useCallback, useEffect, useState } from "react";
import axios from "axios"
import useAcquireTokenSilent from "../../common/useAcquireTokenSilent";
import KeyPerformanceIndicator from "../Custom/KeyPerformanceIndicator";
import { Container, Row } from "reactstrap";

const Header = (props) => {
    const { getToken } = useAcquireTokenSilent();
    const [kpis, setKpis] = useState({});
    const [loading, setLoading] = useState(true);

    const loadData = useCallback(() => {
        setLoading(true);
        getToken(props.configuration)
            .then((token) => {
                axios.get(
                    '/api/KeyPerformanceIndicators/',
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    }
                ).then((result) => {
                    setKpis(result.data);
                    setLoading(false);
                });
            });
    }, [props.configuration, getToken]);

    useEffect(() => {
        loadData();
    }, [loadData]);


    return (
        <>
            <div className="header bg-gradient-secondary pb-8 pt-5 pt-md-8">
                <Container fluid>
                    <div className="header-body">
                        <Row>
                            <KeyPerformanceIndicator
                                title="In Progress Orders"
                                value={kpis?.inProgressOrders ?? 0}
                                colour="success"
                                icon="fa-cart-arrow-down"
                                loading={loading} />
                            <KeyPerformanceIndicator
                                title="In Progress Amount"
                                value={kpis?.inProgressOrdersAmount ?? 0}
                                colour="success"
                                decimalPlaces={2}
                                icon="fa-cart-arrow-down"
                                prefix="&euro;"
                                loading={loading} />
                            <KeyPerformanceIndicator
                                title="Est. Printing Time"
                                value={kpis?.estimatedPrintingTime ?? 0}
                                colour="info"
                                icon="fa-print"
                                suffix="minutes"
                                loading={loading} />
                            <KeyPerformanceIndicator
                                title="Pending Amount (7D)"
                                value={kpis?.ordersPendingLast7DaysAmount ?? 0}
                                colour="primary"
                                decimalPlaces={2}
                                icon="fa-cart-plus"
                                prefix="&euro;"
                                loading={loading} />
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default Header;
