import React from "react";
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle, Spinner, Row, Col } from "reactstrap";

const KeyPerformanceIndicator = ({ title, value, colour, icon, decimalPlaces, prefix, suffix, loading }) => {

    if (decimalPlaces === null || decimalPlaces === undefined) {
        decimalPlaces = 0;
    }

    if (loading === null || loading === undefined) {
        loading = false;
    }

    if (prefix === null || prefix === undefined) {
        prefix = '';
    }

    if (suffix === null || suffix === undefined) {
        suffix = '';
    } 

    const colourClasses = "icon icon-shape text-white rounded-circle shadow bg-" + colour;
    const iconClasses = "fas " + icon;

    return <Col lg="6" xl="3">
        <Card className="card-stats mb-4 mb-xl-0">
            <CardBody>
                <Row>
                    <div className="col">
                        <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                        >
                            {title}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                            {prefix + ' ' + value.toFixed(decimalPlaces) + ' ' + suffix} {loading ? <Spinner color="primary" size="sm" /> : null}
                        </span>
                    </div>
                    <Col className="col-auto">
                        <div className={colourClasses}>
                            <i className={iconClasses} />
                        </div>
                    </Col>
                </Row>
                {/*<p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 3.48%
                      </span>{" "}
                      <span className="text-nowrap">Since last month</span>
                    </p>*/}
            </CardBody>
        </Card>
    </Col>;
}

KeyPerformanceIndicator.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    colour: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    decimalPlaces: PropTypes.number,
    prefix: PropTypes.string,
    loading: PropTypes.bool
}

export default KeyPerformanceIndicator;