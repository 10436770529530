import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios"
import PlainHeader from "../../components/Headers/PlainHeader.js";
import { Container, Row, Card, CardHeader, CardBody, CardImg, Spinner } from "reactstrap";
import useAcquireTokenSilent from "../../common/useAcquireTokenSilent";

const AvailableMockups = (props) => {
    const { getToken } = useAcquireTokenSilent();
    const [availableMockups, setAvailableMockups] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getToken(props.configuration)
            .then((token) => {
                axios.get(
                    '/api/Mockups',
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    }
                ).then((result) => {
                    var allMockupImages = result.data;

                    axios.get(
                        '/api/Products/ByStatus/PreProcessed',
                        {
                            headers: {
                                'Authorization': 'Bearer ' + token
                            }
                        }
                    ).then((result) => {
                        var existingProducts = [];
                        allMockupImages.forEach(m => {
                            if (result.data.filter(p => p.id === m).length === 0) {
                                existingProducts.push(m);
                            }
                        });

                        setAvailableMockups(existingProducts);
                        setLoading(false);
                    });
                });
            });
    }, [getToken, props.configuration]);

    const products = availableMockups.map(m =>
        <div className="col" key={m}>
            <Card style={{ width: "14rem" }}>
                <CardImg
                    alt="..."
                    src={props.configuration.mockupsBaseUrl + m + "/" + m + "_BG02_FB04-NB.jpg"}
                    top
                />
                <CardBody>
                    <Link to={"/Products/ChangeImages/" + m} className="btn btn-primary">Change {m} Images</Link>
                </CardBody>
            </Card>
        </div>);

    return (<>
        <PlainHeader />
        <Container className="mt--7" fluid>
            <Row>
                <div className="col">

                    <Card className="shadow">
                        <CardHeader className="bg-transparent">
                            <h3 className="mb-0">Change Images</h3>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                {loading ? <Spinner color="primary" /> : products}
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </Row>
        </Container>
    </>);
};


export default AvailableMockups;