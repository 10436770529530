import React from "react";

import Dashboard from "./views/Dashboard";

import CategoryList from "./views/categories/CategoryList";
import AddCategory from "./views/categories/AddCategory";

import AddOrder from "./views/orders/AddOrder";
import AccountOrderList from "./views/orders/AccountOrderList";
import OrderList from "./views/orders/OrderList";
import OrderDetails from "./views/orders/OrderDetails";

import AvailableMockups from "./views/products/AvailableMockups";
import AddProduct from "./views/products/AddProduct";
import ChangeImages from "./views/products/ChangeImages";
import AvailableImages from "./views/products/AvailableImages"
import ProductList from "./views/products/ProductList";
import EditProduct from "./views/products/EditProduct";

import DeveloperTools from "./views/developer-tools/DeveloperTools";

var routes = [
    {
        path: "/Dashboard",
        name: "Dashboard",
        icon: "ni ni-tv-2 text-primary",
        component: (props, routeProps) => <Dashboard {...props} {...routeProps} />,
        layout: "/admin",
        showInSidebar: true
    },
    {
        path: "/Categories",
        name: "Categories",
        icon: "ni ni-archive-2 text-blue",
        component: (props, routeProps) => <CategoryList {...props} {...routeProps} />,
        layout: "/admin",
        showInSidebar: true
    },
    {
        path: "/Categories/:parentCategoryId/Add",
        name: "Categories",
        icon: "ni ni-archive-2 text-blue",
        component: (props, routeProps) => <AddCategory {...props} {...routeProps} />,
        layout: "/admin",
        showInSidebar: false
    },
    {
        path: "/Categories/Add",
        name: "Categories",
        icon: "ni ni-archive-2 text-blue",
        component: (props, routeProps) => <AddCategory {...props} {...routeProps} />,
        layout: "/admin",
        showInSidebar: false
    },
    {
        path: "/Products/Edit/:sku",
        name: "Edit Product",
        icon: "ni ni-basket text-orange",
        component: (props, routeProps) => <EditProduct {...props} {...routeProps} />,
        layout: "/admin",
        showInSidebar: false
    },
    {
        path: "/Products/Add/:sku",
        name: "Create Product",
        icon: "ni ni-basket text-orange",
        component: (props, routeProps) => <AddProduct {...props} {...routeProps} />,
        layout: "/admin",
        showInSidebar: false
    },
    {
        path: "/Products/ChangeImages/:sku",
        name: "Update Product Images",
        icon: "ni ni-basket text-orange",
        component: (props, routeProps) => <ChangeImages {...props} {...routeProps} />,
        layout: "/admin",
        showInSidebar: false
    },
    {
        path: "/Products/Add",
        name: "Create Product",
        icon: "ni ni-basket text-orange",
        component: (props, routeProps) => <AvailableMockups {...props} {...routeProps} />,
        layout: "/admin",
        showInSidebar: true
    },
    {
        path: "/Products",
        name: "Trending Products",
        icon: "ni ni-basket text-orange",
        component: (props, routeProps) => <ProductList {...props} {...routeProps} />,
        layout: "/admin",
        showInSidebar: true
    },
    {
        path: "/Products/ChangeImages",
        name: "Update Product Images",
        icon: "ni ni-basket text-orange",
        component: (props, routeProps) => <AvailableImages {...props} {...routeProps} />,
        layout: "/admin",
        showInSidebar: true
    },
    {
        path: "/Orders/:type",
        name: "Orders",
        icon: "ni ni-credit-card text-yellow",
        component: (props, routeProps) => <OrderList {...props} {...routeProps} />,
        layout: "/admin",
        showInSidebar: true
    },
    {
        path: "/ManualOrder",
        name: "Create Order",
        icon: "ni ni-credit-card text-yellow",
        component: (props, routeProps) => <AddOrder {...props} {...routeProps} />,
        layout: "/admin",
        showInSidebar: true
    },
    {
        path: "/Accounts/:accountId/Orders/",
        name: "Account Orders",
        icon: "ni ni-credit-card text-yellow",
        component: (props, routeProps) => <AccountOrderList {...props} {...routeProps} />,
        layout: "/admin",
        showInSidebar: false
    },
    {
        path: "/OrderDetails/:orderId",
        name: "Order Details",
        icon: "ni ni-credit-card text-yellow",
        component: (props, routeProps) => <OrderDetails {...props} {...routeProps} />,
        layout: "/admin",
        showInSidebar: false
    },
    {
        path: "/DevTools/",
        name: "Dev Tools",
        icon: "ni ni-settings text-grey",
        component: (props, routeProps) => <DeveloperTools {...props} {...routeProps} />,
        layout: "/admin",
        showInSidebar: true
    }
];
export default routes;
