import React, { useCallback, useEffect, useState } from "react";
import { NavLink as NavLinkRRD, useParams, Link } from 'react-router-dom';
import classnames from "classnames";
import axios from "axios"
import PlainHeader from "../../components/Headers/PlainHeader.js";
import { Container, Row, Card, CardHeader, CardBody, Table, Badge, Nav, NavItem, NavLink, Spinner } from "reactstrap";

import useAcquireTokenSilent from "../../common/useAcquireTokenSilent";

const OrderList = (props) => {
    const { type } = useParams();
    const { getToken } = useAcquireTokenSilent();
    const [ orders, setOrders ] = useState([]);
    const [loading, setLoading] = useState(true);
    console.log(orders);
    const getData = useCallback(() => {
        setLoading(true);
        getToken(props.configuration)
            .then((token) => {
                axios.get(
                    '/api/Orders/' + type,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    }
                ).then((result) => {
                    result.data.sort((a, b) => b.orderCreatedOn.localeCompare(a.orderCreatedOn));
                    setOrders(result.data);
                    setLoading(false);
                });
            });
    }, [getToken, props.configuration, type]);

    useEffect(() => {
        if (type === ":type") {
            props.history.push('/orders/InProgress');
        } else if (type !== ":type") {
            setOrders([]);
            getData();
        }
    }, [type, getData,props.history]);

    const orderStatus = status => {
        /*Pending = 0,
        PaymentReceived = 1,
        InProgress = 2,
        Shipped = 3,
        Delayed = 4,
        Canceled = 5,
        RequiresReview = 6.
        Reviewed = 7*/
        switch (status) {
            case 0:
                return (<Badge color="" className="badge-dot mr-4">
                    <i className="bg-warning" />
                        Pending
                </Badge>);
            case 1:
                return (<Badge color="" className="badge-dot mr-4">
                    <i className="bg-info" />
                        Payment Received
                </Badge>);
            case 2:
                return (<Badge color="" className="badge-dot mr-4">
                    <i className="bg-info" />
                        In Progress
                </Badge>);
            case 3:
                return (<Badge color="" className="badge-dot mr-4">
                    <i className="bg-success" />
                        Shipped
                </Badge>);
            case 6:
                return (<Badge color="" className="badge-dot mr-4">
                    <i className="bg-warning" />
                    Requires Review
                </Badge>);
            case 7:
                return (<Badge color="" className="badge-dot mr-4">
                    <i className="bg-info" />
                    Reviewed
                </Badge>);
            case 8:
                return (<Badge color="" className="badge-dot mr-4">
                    <i className="bg-warning" />
                    Manual Intervention
                </Badge>);
            case 9:
                return (<Badge color="" className="badge-dot mr-4">
                    <i className="bg-warning" />
                    Creating
                </Badge>);
            case 10:
                return (<Badge color="" className="badge-dot mr-4">
                    <i className="bg-info" />
                    Ready for Processing
                </Badge>);
            default:
                return (status);
        }
    }
    return (<>
        <PlainHeader />
        <Container className="mt--7" fluid>
            <Row>
                <div className="col">
                    <Card className="shadow">
                        <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                                <div className="col">
                                    <h3 className="mb-0">Orders</h3>
                                </div>
                                <div className="col">
                                    <Nav className="justify-content-end" pills>
                                        <NavItem>
                                            <NavLink
                                                className={classnames("py-2 px-3", {
                                                    active: type === "Creating",
                                                })}
                                                to="/orders/Creating"
                                                tag={NavLinkRRD}
                                            >
                                                <span className="d-none d-md-block">Creating</span>
                                                <span className="d-md-none">C</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames("py-2 px-3", {
                                                    active: type === "Pending",
                                                })}
                                                to="/orders/Pending"
                                                tag={NavLinkRRD}
                                            >
                                                <span className="d-none d-md-block">Pending</span>
                                                <span className="d-md-none">P</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames("py-2 px-3", {
                                                    active: type === "InProgress",
                                                })}
                                                to="/orders/InProgress"
                                                tag={NavLinkRRD}
                                            >
                                                <span className="d-none d-md-block">In Progress</span>
                                                <span className="d-md-none">P</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames("py-2 px-3", {
                                                    active: type === "Completed",
                                                })}
                                                data-toggle="tab"
                                                to="/orders/Completed"
                                                tag={NavLinkRRD}
                                            >
                                                <span className="d-none d-md-block">Completed</span>
                                                <span className="d-md-none">C</span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Order Id</th>
                                        <th scope="col">Recipient</th>
                                        <th scope="col">Created On</th>
                                        <th scope="col">No of Cards</th>
                                        <th scope="col">Order Total</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? <Spinner color="primary" /> : orders.map(o =>
                                        <tr key={o.orderId}>
                                            <th scope="row"><Link to={"/OrderDetails/" + o.orderId}>{o.orderId}</Link></th>
                                            <td>{o?.recipientDetails?.recipientName}</td>
                                            <td>{new Date(o.orderCreatedOn).toLocaleString()}</td>
                                            <td>{o.orderItems.map(a => a.quantity).reduce((a, b) => a + b, 0)}</td>
                                            <td>{o.orderTotal.toFixed(2)}</td>
                                            <td>{orderStatus(o.orderStatus)}</td>
                                        </tr>)}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </div>
            </Row>
        </Container>
    </>);
};


export default OrderList;